export const state = {
	btnView: false,
	btnEdit: false,
	btnDelete: false,
	btnCustom: false,
	btnCustomTitle: '',
}

export const mutations = {
	SET_ABLE(state, payload) {
		const { btnView, btnEdit, btnDelete, btnCustom, btnCustomTitle } = payload
		state.btnView = btnView
		state.btnEdit = btnEdit
		state.btnDelete = btnDelete
		state.btnCustom = btnCustom
		state.btnCustomTitle = btnCustomTitle
	},
}
