<template>
  <panel title="ไฟล์ที่เกี่ยวข้อง" bordered :isExpand="false">
    <b-row>
      <b-col>
        <input
          type="file"
          ref="fileAttach"
          @change="handleFile"
          class="d-none"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <ul class="attached-document clearfix">
          <li class="" v-for="(row, index) in datas" :key="index">
            <div class="document-file">
              <a href="javascript:;" v-if="row.file_mime.startsWith('image')">
                <img :src="row.link_url" class="img-fluid" />
              </a>
              <a href="javascript:;" v-else>
                <i class="fa fa-file-pdf"></i>
              </a>
            </div>
            <div class="document-name">
              <a href="javascript:;">{{ row.file_name }}</a>
              <span class="float-right">
                <i class="fa fa-info-circle" @click="infoFile(index)"></i>
                <i class="fa fa-trash" @click="deleteFile(index)"></i>
              </span>
            </div>
          </li>

          <li>
            <div class="document-file" @click="selectFile">
              <a href="javascript:;">
                <i class="fas fa-plus"></i>
              </a>
            </div>
          </li>
        </ul>
      </b-col>
    </b-row>
  </panel>
</template>

<script>
export default {
  props: {
    groupName: {
      type: String,
      required: false
    },
    dataID: {
      type: String,
      required: false
    },
    typeName: {
      type: String,
      required: false
    },
    AttachedID: {
      type: String,
      required: false
    }
  },
  data: () => ({
    datas: [],
    data: {
      deposit_data_id: '',
      deposit_attachedfile_id: '',
      file: '',
      deposit_attachedfile_item_id: '',
      file_name: '',
      file_path: '',
      file_mime: '',
      link_url: '',
      date: '',
      menu_order: ''
    },
    fileAttach: ''
  }),
  watch: {
    AttachedID: {
      handler: function(value) {
        if (value) {
          this.get(`/api/depositattachedfile/${this.AttachedID}`).then(
            ({ data }) => {
              const self = this
              data.forEach(function(val) {
                let temp = {
                  deposit_data_id: self.dataID,
                  deposit_attachedfile_id: self.AttachedID,
                  type: self.typeName,
                  file: '',
                  deposit_attachedfile_item_id: val.id,
                  file_name: val.file_name,
                  file_path: val.file_path,
                  file_mime: val.file_mime,
                  link_url: '/api/storage/' + val.file_path,
                  date: val.date,
                  menu_order: val.menu_order
                }
                self.datas.push(temp)
              })
            }
          )
        }
      }
    },
    fileAttach: {
      handler: function(value) {
        if (value) {
          let temp = {
            deposit_data_id: this.dataID,
            deposit_attachedfile_id: this.AttachedID,
            type: this.typeName,
            file: value,
            deposit_attachedfile_item_id: '',
            file_name: value.name,
            file_path: '',
            file_mime: value.type,
            link_url: URL.createObjectURL(value),
            date: '',
            menu_order: ''
          }
          this.datas.push(temp)
        }
      }
    }
  },
  methods: {
    submit() {
      if (this.datas.length == 0 && this.AttachedID) {
        return this.del(`/api/depositattachedfile/${this.AttachedID}`)
      } else {
        return this.post(`/api/depositattachedfile`, this.datas)
      }
    },
    handleFile(e) {
      this.fileAttach = ''
      this.fileAttach = e.target.files[0]
    },
    selectFile() {
      this.$refs.fileAttach.click()
    },
    infoFile(id) {
      console.log(this.datas[id])
    },
    deleteFile(id) {
      console.log(this.datas[id])
      this.datas.splice(id, 1)
    },
    clearData() {
      this.datas = []
      this.fileAttach = ''
      this.$refs.fileAttach.value = ''
    },
    setDepositDataID(id) {
      this.datas.map((item, key) => {
        this.datas[key].deposit_data_id = id
      })
    }
  }
}
</script>

<style></style>
