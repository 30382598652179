<template>
  <b-row>
    <div class="page-header-box">
      <h1 class="page-header">{{ title }}</h1>
    </div>
    <!-- Search -->
    <b-col sm="6">
      <treeselect
        :multiple="false"
        :async="true"
        :load-options="loadOptions"
        loadingText="กำลังค้นหา..."
        placeholder="กรอกเพื่อค้นหา"
        noOptionsText="ไม่พบรายการให้เลือก"
        searchPromptText="กรอกเพื่อค้นหา เช่น รหัสสมาชิก, ชื่อ-นามสกุล, เลขบัญชีเงินฝาก"
        noResultsText="ไม่พบข้อมูล"
        v-model="vModel"
        :id="formId"
        :normalizer="normalizer"
        ref="searchingNavbar"
      >
        <label
          slot="option-label"
          slot-scope="{
            node,
            shouldShowCount,
            count,
            labelClassName,
            countClassName,
          }"
          :class="labelClassName"
        >
          <strong>{{ node.label }}</strong>
        </label>
      </treeselect>
    </b-col>

    <!-- group button -->
    <b-col sm="4">
      <button type="button" class="btn btn-warning" @click="reading">
        <i class="fas fa-id-card"></i>
        อ่านจากบัตร
      </button>

      <button type="button" id="btn-citizen-search" class="btn btn-info" @click="searchLists">
        <i class="fas fa-search"></i>
        ค้นหา
      </button>

      <button type="button" id="btn-citizen-search" class="btn btn-primary" @click="clickNew">
        <i class="fas fa-add"></i>
        เพิ่มข้อมูลใหม่
      </button>
    </b-col>
  </b-row>
</template>
<script>
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import Treeselect from '@riophae/vue-treeselect'
import { mapState } from 'vuex'
export default {
  props: {
    formId: null,
    title: String,
    type: String,
    url: String,
  },
  data() {
    return {
      check: false,
      items: '',
      vModel: null,
      options: [],
    }
  },
  components: { Treeselect },
  computed: {
    ...mapState('member', ['datas']),
  },
  watch: {
    vModel: {
      handler: async function () {
        let data = await this.options.find((f) => f.id === this.vModel)
        this.$emit('callback', data)
        this.options = []
      },
      deep: true,
    },
  },
  methods: {
    keyupCheck(key) {
      if (key.key == 'F4') {
        this.focusTree()
      }
    },
    focusTree() {
      console.log('focus')
      this.$refs.searchingNavbar.$el
        .getElementsByClassName('vue-treeselect__input')[0]
        .focus()
    },
    reading() {
      this.loading = true
      setTimeout(() => {
        try {
          SmartCard.reading(this.formId, false)
        } catch (e) {
          this.loading = false
          this.alertWarning('ไม่สามารถเชื่อมต่อเครื่องอ่านบัตรได้')
        }
      }, 500)
    },
    clickNew() {
      this.$emit('clickNew', '')
    },
    searchLists() {
      if (this.type == 'partner') {
        this.$router.push('/officer/partner')
      } else if (this.type == 'member') {
        this.$router.push('/officer/member')
      } else {
        this.$router.push('/officer/personal')
      }
    },
    /**
     * Customs TreeSelect
     */
    normalizer(node) {
      try {
        let { id, label_content, label_title } = node
        this.options.push(node)
        return {
          id,
          label: `${label_title} => ${label_content} `,
        }
      } catch (error) {
        return ''
      }
    },

    /**
     * Async data search
     */
    async loadOptions({ action, searchQuery, callback }) {
      try {
        console.log(searchQuery)
        if (action === ASYNC_SEARCH) {
          let {
            data: { data },
          } = await this.post(this.url, {
            searchQuery,
          })
          callback(null, data)
        }
      } catch (error) {
        console.error(error)
        return ''
      }
    },
  },
  mounted() {
    window.addEventListener('keyup', this.keyupCheck)
    const self = this
    $(function () {
      $(`#${self.formId}`).on('reading', async function (event, data) {
        const personal = JSON.parse(data)
        if (personal.error) {
          self.alertWarning('ไม่สามารถอ่านบัตรได้')
          return
        }
        console.log('reading ', data)
        // self.text = personal.Citizenid
        let tmp = await self.post(self.url, {
          searchQuery: personal.Citizenid,
        })
        self.vModel = null
        self.$emit('callback', {
          id: tmp.data.data[0] != undefined ? tmp.data.data[0].id : null,
          action: null,
        })
      })
    })
  },
  destroyed() {
    window.removeEventListener('keyup', this.keyupCheck)
  },
}
</script>
