<template>
  <div>
    <slot></slot>
    <!-- <slot v-if="allow"></slot>
    <b-alert v-else show variant="warning">ขออภัยระดับสิทธิ์ของท่านไม่สามารถใช้งานส่วนนี้ได้</b-alert>-->
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  // mounted() {
  // 	debugger
  // },
  props: {
    name: {
      type: String,
      default: '',
    },
    readable: {
      type: Boolean,
      default: true,
    },
    writeable: {
      type: Boolean,
      default: false,
    },
    deleteable: {
      type: Boolean,
      default: false,
    },
    approveable: {
      type: Boolean,
      default: false,
    },
    approveconditionable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    allow() {
      try {
        let permits = {}
        let key = Object.keys(this.routes).find((name) => name == this.name)
        if (!key) {
          throw "The prop key doesn't match with object"
        }
        permits = this.checkDecPermission(this.routes[key].permit)

        return (
          (this.readable === true ? permits['can_read'] : true) &&
          (this.writeable === true ? permits['can_write'] : true) &&
          (this.deleteable === true ? permits['can_delete'] : true) &&
          (this.approveable === true ? permits['can_approve'] : true) &&
          (this.approveconditionable === true
            ? permits['can_approve_condition']
            : true)
        )
      } catch (error) {
        console.log(error)
        return false
      }

      // if (this.name === '') return true
      // return Object.keys(this.routes).find((key) => key === this.name)
    },
    ...mapState(['routes']),
  },
  methods: {},
}
</script>

<style></style>
