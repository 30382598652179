

<template>
  <b-modal size="xl" title="การอนุมัติแบบมีเงื่อนไข" ref="approve-modal" no-close-on-backdrop>
    <b-row>
      <b-col md="7">
        <div class="alert alert-warning fade show">
          <strong>มีรายการค้ำประกัน</strong>
          กรุณาจัดการเงื่อนไขให้สำเร็จ
        </div>
        <div class="alert alert-danger fade show">
          <strong>มีหนี้มากกว่า</strong>
          กรุณาจัดการเงื่อนไขให้สำเร็จ
        </div>
        <div class="alert alert-success fade show">
          <strong>ไม่ติดเงื่อนไข</strong>
          สามารถดำเนินการได้
        </div>
      </b-col>

      <b-col md="5">
        <div class="form-group m-b-15">
          <input
            type="text"
            class="form-control form-control-lg"
            placeholder="Username"
            v-model="username"
            required
          />
        </div>
        <div class="form-group m-b-15">
          <input
            type="password"
            class="form-control form-control-lg"
            placeholder="Password"
            v-model="password"
            required
          />
        </div>
      </b-col>
    </b-row>

    <template v-slot:modal-footer="{ ok, cancel, hide }">
      <b-button variant="secondary" @click="hide">ยกเลิก</b-button>
      <b-button variant="warning" @click="submit('ปกติ')">บันทึก</b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
	props: {
		textSave: {
			type: String,
			default: 'บันทึก',
		},
		textCancel: {
			type: String,
			default: 'ยกเลิก',
		},
		btnCancelAble: {
			type: Boolean,
			default: true,
		},
		okTitle: null,
		title: {
			type: String,
			default: '',
		},
		loading: null,
		size: null,

		// 'okTitle', 'title', 'loading', 'size'
	},
	watch: {},
	methods: {
		request(e) {
			this.$emit('request', e)
		},
		save(e) {
			this.$emit('save', e)
		},
		cancel(e) {
			this.$emit('cancel', e)
			this.hide()
		},
		show() {
			this.$emit('show')
			this.$refs['approve-modal'].show()
		},
		hide() {
			this.$emit('close')
			this.$refs['approve-modal'].hide()
		},
	},
}
</script>

<style></style>

