<template>
  <div>
    <b-col>
      <b-form-group>
        <b-form-radio-group
          id="radio-group-type"
          v-model="selectType"
          :options="optionsType"
          name="radio-options-type"
        ></b-form-radio-group>
      </b-form-group>
    </b-col>

    <form-treeselect
      v-model="selectDivisions"
      url="/api/helper/listDivision"
      method="post"
      trackChildren="items"
      trackLable="name"
      multiple
      label="เลือกสังกัด / หน่วยงาน"
      :col="12"
      v-show="selectType == 'custom'"
    />

    <b-col v-show="selectType == 'custom'">
      <div class="form-group">
        <label for="name">สมาชิก / บุคลากร</label>
        <v-select
          :options="optionsProfiles"
          v-model="selectProfiles"
          :reduce="item => item.id"
          label="name"
          multiple
        ></v-select>
      </div>
    </b-col>

    <!-- <b-col>
      {{ answers }}
    </b-col>-->
  </div>
</template>

<script>
/**
 * การใช้งาน
 *  ** ดึงค่าจากตัวแปร answers ได้เลย
 *
 * การทำงาน
 *  ถ้าเลือกทั้งหมด จะได้ member,hr ทั้งหมดที่ยังเป็นสถานะปกติมา
 *  ถ้าเลือกกำหนดเอง จะเป็นการเอามา or ของทั้งสองตัวเลือก
 */
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css'
export default {
	components: {
		vSelect
	},
	data() {
		return {
			answers: [],
			selectType: 'all',
			selectDivisions: [],
			selectProfiles: [],
			optionsProfiles: [],
			optionsType: [
				{ text: 'ทั้งหมด', value: 'all' },
				{ text: 'กำหนดเอง', value: 'custom' }
			]
		}
	},
	watch: {
		selectType() {
			this.clearData()
			this.checkListProfile()
		},
		selectDivisions() {
			this.checkListProfile()
		},
		selectProfiles() {
			this.checkListProfile()
		}
	},
	mounted() {
		this.listMember()
		this.checkListProfile()
	},
	methods: {
		_return() {
			const _return = {
				selectType: this.selectType,
				selectDivisions: this.selectDivisions,
				selectProfiles: this.selectProfiles
			}

			this.$emit('callback', _return)
		},
		async listMember() {
			try {
				this.optionsProfiles = await this.api.post('/api/helper/listMember')
				return ''
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		async checkListProfile() {
			try {
				this.answers = await this.api.post('/api/helper/checkListProfile', {
					select_type: this.selectType,
					select_divisions: this.selectDivisions,
					select_profiles: this.selectProfiles
				})
				this._return()
				return ''
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		clearData() {
			this.selectDivisions = []
			this.selectProfiles = []
		}
	}
}
</script>
