<template>
  <b-row>
    <!-- 
      Data table
		-->
    <b-col sm="12" class="mt-1">
      <data-table
        :columns="_columns"
        :data="data"
        @on-table-props-changed="reloadTable"
        :filters="filters"
      >
        <div slot="filters" slot-scope="{ tableFilters, perPage }">
          <div class="row">
            <b-col sm="8" class="mt-2">
              <button
                v-if="newAble"
                type="button"
                class="btn btn-primary"
                @click="$emit('clickNew', '')"
              >
                เพิ่ม
              </button>
            </b-col>

            <div class="col-md-4">
              <b-row>
                <form-input
                  v-model="tableFilters.search"
                  :col="12"
                  pl="ค้นหาข้อมูลในตาราง"
                />
                <form-select
                  v-model="tableFilters.length"
                  :options="perPage"
                  :col="12"
                />
              </b-row>
            </div>
          </div>
        </div>
        <!-- pagination -->
        <div slot="pagination" slot-scope="{ links = {}, meta = {} }">
          <nav class="row">
            <div class="col-md-6 text-left">
              <span
                >แสดง {{ meta.from }} จาก {{ meta.to }} ถึง
                {{ meta.total }}</span
              >
            </div>
            <div class="col-md-6 text-right">
              <button
                :disabled="!links.prev"
                class="btn btn-primary btn-sm"
                @click="url = links.prev"
              >
                ย้อนกลับ
              </button>
              <button
                :disabled="!links.next"
                class="btn btn-primary ml-2 btn-sm"
                @click="url = links.next"
              >
                หน้าต่อไป
              </button>
            </div>
          </nav>
        </div>
      </data-table>
    </b-col>
  </b-row>
</template>
<script>
// Document: https://jamesdordoy.github.io/laravel-vue-datatable/installation
import axios from "@/libs/axios"

import { mapMutations, mapState } from "vuex"
export default {
  data() {
    return {
      data: {},
      tableProps: {
        search: "",
        length: 10,
        column: "id",
        dir: "asc",
      },
      selectOptions: [],

      _columns: [],
    }
  },
  props: {
    filters: {
      type: Object,
    },
    url: {
      type: String,
      default: null,
    },
    /**
     * Columns
     * return [
     * {
     *  label: '#',
     *  name: 'id',
     *  orderable: true,
     * },
     * ]
     */
    columns: Array,
    newAble: {
      type: Boolean,
      default: true,
    },
    viewAble: {
      type: Boolean,
      default: true,
    },
    editAble: {
      type: Boolean,
      default: true,
    },
    deleteAble: {
      type: Boolean,
      default: true,
    },
    /**
     * Custom button
     */
    btnCustom: {
      type: Boolean,
      default: false,
    },
    btnCustomTitle: null,
  },

  created() {
    this.beforeCreateCheckButtonAble()
    this.getData(this.url)
  },

  methods: {
    reload() {
      this.getData()
    },
    async confirmDeleteData(data) {
      try {
        const result = await this.$swal({
          title: "C",
          text: "are you sure want to delete",
          icon: "warning",

          showCancelButton: true,
          confirmButtonText: "ยืนยันการลบข้อมูล",
          cancelButtonText: "ยกเลิก",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        })

        /**
         * If Confirm Delete data
         */
        if (result.value) {
          let _url = JSON.parse(JSON.stringify(this.url))
          _url = _url.replace("/index", "")
          await this.api.del(`${_url}/${data.id}`, data)
          await this.$swal({
            icon: "success",
            title: "Successfully deleted",
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: "btn btn-success",
            },
          })
          this.getData()
        }
      } catch (error) {
        console.error(error)
        return ""
      }
    },

    /**
     * ก่อนสร้างให้ตรวจสอบ button view,edit,delete
     * @return void
     */
    beforeCreateCheckButtonAble() {
      let columns = {
        label: "",
        name: "",
        orderable: false,
        classes: {
          btn: true,
          "btn-primary": true,
          "btn-sm": true,
        },
        event: "click",
        handler: this.callback,
        component: () => import("./TableAPIbutton/clickView"),
      }
      const _x = JSON.parse(JSON.stringify(this.columns))
      _x.push(columns)
      this.SET_ABLE({
        btnView: this.viewAble,
        btnEdit: this.editAble,
        btnDelete: this.deleteAble,
        btnCustom: this.btnCustom,
        btnCustomTitle: this.btnCustomTitle,
      })
      this._columns = _x
    },
    ...mapMutations("tableAPI", ["SET_ABLE"]),
    /**
     * เมื่อกดปุ่มแล้วจะให้ตอบกลับมาที่ function นี้
     * @param {Object}  data        ข้อมูลที่กด
     * @param {String}  actionType  ประเภทของปุ่มที่กด
     */
    callback(data, actionType) {
      if (actionType === "clickDelete") {
        return this.confirmDeleteData(data)
      }
      return this.$emit(actionType, data)
    },
    getData(url = this.url, options = this.tableProps) {
      axios
        .post(url, {
          params: options,
        })
        .then((response) => {
          this.data = response.data
        })
        // eslint-disable-next-line
        .catch((errors) => {
          console.error(errors)
          return ""
        })
    },
    reloadTable(tableProps = "") {
      this.getData(this.url, tableProps)
    },
  },
}
</script>
