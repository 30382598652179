<template>
  <div>
    <div class="d-flex align-items-center justify-content-between">
      <div>{{ title }}</div>
      <div class="tree-icon-action" @click="clickNew">
        <a href="#" class="btn btn-primary text-decoration-none">{{
          newTitle
        }}</a>
      </div>
    </div>
    <tree-menu
      v-for="(item, index) in topics"
      :key="`folder-${index}`"
      :label="item.label"
      :nodes="item.nodes"
      :depth="0"
      :selectedNode="selectedNode"
      :onSelect="onSelect"
      :addSubTopic="addSubTopic"
      :max="6"
      :group="[item]"
    ></tree-menu>
  </div>
</template>
<script>
const TreeMenu = () => import('./TreeMenu')
export default {
  components: { TreeMenu },
  props: {
    title: {
      type: String,
      default: 'หัวข้อพัสดุ'
    },
    newTitle: {
      type: String,
      default: 'เพิ่มหัวข้อใหม่'
    },
    value: {
      default: null
    },
    topics: {
      type: Array,
      default: null
    }
  },
  data() {
    return {
      selectedNode: null,
      subTopicLabel: null,
      subTopic: {
        name: null
      },
      topic: {
        name: null
      },
      selectedGroup: null
    }
  },
  watch: {
    value(val) {
      this.value = val
      this.selectedNode = val[val.length - 1].label
      this.selectedGroup = val
    },
    topics(val) {
      this.topics = val
    }
  },
  mounted() {
    this.selectedNode = this.value
      ? this.value[this.value.length - 1].label
      : null
    console.log(this.value, this.selectedNode)
    console.log(this.topics)
  },
  methods: {
    onSelect(group) {
      this.selectedNode = group[group.length - 1].label
      this.selectedGroup = group
      this.$emit('selected:nodes', this.selectedGroup)
    },
    clickNew() {
      this.$emit('clickNew')
      // this.topic.name = null
      // this.$refs.topicModal.show()
    },
    addSubTopic(label) {
      this.subTopic.name = null
      this.subTopicLabel = label
      console.log(label)
      // this.$refs.subTopicModal.show()
      this.$emit('clickDetail')
    },
    handleOk(type) {
      const name = type === 'topic' ? this.topic.name : this.subTopic.name
    },
    handleCancel() {
      this.subTopic.name = null
      this.topic.name = null
    }
  }
}
</script>
<style scoped>
.tree-icon-action {
  margin-left: 4px;
  font-size: 12px;
  cursor: pointer;
}
.tree-icon-action:hover {
  opacity: 0.8;
}
</style>
