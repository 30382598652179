<template>
  <div>
    <b-form-input
      class="custom-select"
      v-model="inputVal"
      ref="select-searchable"
      :list="`${this.id}-list`"
      @focus="clearInput"
      @blur="checkInput"
      :disabled="disabled || false"
      :state="state"
      autocomplete="off"
      size="sm"
    />
    <div
      :tabindex="0"
      :hidden="!isFocus"
      class="select-search-list"
      ref="select-searchable-list"
      @blur="onUnSelected"
    >
      <div
        v-for="(item, index) in options"
        :key="`select-item-${index}`"
        :class="`select-search-item`"
        @click="onSelected(item)"
        @mouseover="setHover(item)"
        @mouseout="setHover(null)"
        :hidden="!item.text.includes(inputVal) && inputVal !== null"
      >
        {{ item.text }}
      </div>
    </div>
    <b-form-input
      v-model="selected"
      :id="id"
      :name="name"
      :list="`${this.id}-list`"
      :disabled="disabled || false"
      :state="state"
      hidden
    />
    <!-- ส่วนการ validate -->
    <b-form-invalid-feedback id="input-live-feedback" v-if="_error">{{
      errors.items.find(f => f.field === name).msg
    }}</b-form-invalid-feedback>
  </div>
</template>

<script>
export default {
  props: {
    name: String,
    id: String,
    options: Array,
    value: null,
    disabled: Boolean,
    placeholder: String,
    invalidFeedback: String,
    state: null
  },
  data() {
    return {
      inputVal: null,
      isFocus: false,
      selected: this.value,
      history: this.value,
      hover: false
    }
  },
  mounted() {
    if (this.value) {
      const obj = this.options.find(item => item.value === this.value)
      this.inputVal = obj !== undefined ? obj.text : null
      // this.history = obj !== undefined ? obj.text : null
    }
  },
  beforeUpdate() {
    if (this.value) {
      this.selected = this.value
      const obj = this.options.find(item => item.value === this.value)
      this.inputVal = obj !== undefined ? obj.text : null
      // this.history = obj !== undefined ? obj.text : null
    }
  },
  computed: {
    _state() {
      let val = this.value
      let error = this._error
      if (!val && !this.dirty) {
        return null
      } else if (error) {
        return false
      } else {
        return true
      }
    },
    _error() {
      return this.errors.items.find(f => f.field === this.name)
    }
  },
  watch: {
    inputVal(value) {
      const obj = this.options.find(item => item.text === value)
      this.selected = obj !== undefined ? obj.value : null
    },
    selected(value) {
      this.$emit('input', value)
      if (value) this.$nextTick(() => this.$refs['select-searchable'].blur())
    },
    value(value) {
      if (!value) {
        this.selected = null
        this.inputVal = null
      } else {
        this.selected = this.value
        const obj = this.options.find(item => item.value === this.value)
        this.inputVal = obj !== undefined ? obj.text : null
        // this.history = obj !== undefined ? obj.text : null
      }
    }
  },
  methods: {
    checkInput() {
      // if (this.history) {
      //   this.inputVal = this.history
      // } else {
      //   const obj = this.options.find(item => item.text === this.inputVal)
      //   this.inputVal = obj !== undefined ? obj.text : null
      // }
      const obj = this.options.find(item => item.text === this.inputVal)
      this.inputVal = obj !== undefined ? obj.text : null
      if (this.hover) this.onSelected(this.hover)
      else this.isFocus = false
    },
    clearInput() {
      this.isFocus = true
      if (this.state === false) {
        this.selected = null
        this.inputVal = null
        // this.history = null
      }
      this.$emit('input', null)
    },
    setHover(item) {
      this.hover = item
    },
    onSelected(item) {
      this.inputVal = item.text
      this.selected = item.value
      this.$emit('input', item.value)
      this.isFocus = false
      this.hover = null
    },
    onUnSelected() {
      this.isFocus = false
    }
  }
}
</script>
<style>
.custom-select {
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3E%3Cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3E%3C/svg%3E")
    no-repeat right 0.75rem center/8px 10px !important;
}
.select-search-list {
  z-index: 10;
  position: absolute;
  max-height: 300px;
  overflow: auto;
  background-color: #fff;
  width: 100%;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}
.select-search-item {
  width: 100%;
  padding: 4px 16px;
}
.select-search-item:hover {
  background-color: #007bff;
  width: 100%;
  padding: 4px 16px;
  color: #fff;
  cursor: pointer;
}
.selected {
  background-color: #007bff;
  color: #fff;
}
</style>
