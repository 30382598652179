<template>
  <ul class="nav" :class="type === 'pills' ? 'nav-pills' : 'nav-tabs'">
    <li class="nav-items" v-for="(item, i) in data" :key="i">
      <router-link
        :to="item.url"
        class="nav-link"
        :class="{ active: item.active }"
        :exact="item.exact"
        :data-toggle="dataToggle"
        @click.native="onTabSelect(item, i)"
      >
        <span class="d-sm-none">Tab {{ i }}</span>
        <span class="d-sm-block d-none" @click="onTabSelect(item, i)">{{
          item.label
        }}</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
export default {
  props: ['data', 'type', 'dataToggle'],
  methods: {
    onTabSelect(item, i) {
      this.$emit('select', item, i)
    }
  }
}
</script>

<style></style>
