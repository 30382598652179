<template>
  <div class="page-header-box">
    <h1 class="page-header">{{ title }}</h1>
    <div class="search-box">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          id="user-citizen-id"
          ref="userSearchBox"
          v-model="text"
          v-on:keyup.enter="search"
        />
        <div class="input-group-append">
          <button
            type="button"
            class="btn btn-warning"
            :id="formId"
            @click="reading"
          >
            <i class="fas fa-id-card"></i>
          </button>
          <button
            type="button"
            class="btn btn-primary"
            id="btn-citizen-search"
            @click="search"
          >
            <i class="fas fa-search"></i>
          </button>
        </div>
      </div>
      <button
        style="margin-left:10px;"
        type="button"
        class="btn btn-success"
        id="btn-citizen-new"
        @click="open"
      >
        เพิ่ม
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'formId'],
  data: () => ({
    text: ''
  }),
  mounted() {
    const self = this
    $(function() {
      $(`#${self.formId}`).on('reading', function(event, data) {
        const personal = JSON.parse(data)
        if (personal.error) {
          self.alertWarning('ไม่สามารถอ่านบัตรได้')
          return
        }
        self.text = personal.Citizenid
        self.$emit('search', self.text)
      })
    })
  },
  destroyed() {
    this.text = ''
  },
  methods: {
    reading() {
      this.loading = true
      setTimeout(() => {
        try {
          SmartCard.reading(this.formId, false)
        } catch (e) {
          this.loading = false
          this.alertWarning('ไม่สามารถเชื่อมต่อเครื่องอ่านบัตรได้')
        }
      }, 500)
    },
    search() {
      this.$emit('search', this.text)
    },
    open() {
      this.$emit('clickNew')
    }
  }
}
</script>

<style></style>
