<template>
  <b-modal
    ref="history-modal"
    hide-footer
    :title="`ประวัติการแก้ไข${title}` || ''"
    v-if="id && table && field"
    size="lg"
  >
    <data-table-local
      :id="`history-${field}`"
      :fields="fields"
      :items="items"
      :newable="false"
      :editable="false"
      :deleteable="false"
      :viewable="false"
    />
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      id: null,
      table: null,
      field: null,
      title: null,
      isBusy: true,
      items: [],
      fields: {
        no: {
          label: 'ลำดับที่'
        },
        data: {
          label: 'ข้อมูลเก่า'
        },
        data_new: {
          label: 'ข้อมูลใหม่'
        },
        requestor: {
          label: 'ผู้ขอแก้ไข'
        },
        created_at: {
          label: 'วันเดือนปี'
        }
      }
    }
  },
  watch: {
    items: function(val) {
      this.items = val
    }
  },
  methods: {
    show(id, table, field, title) {
      this.id = id
      this.table = table
      this.field = field
      this.title = title
      this.get(`/api/history/${table}/${id}/${field}`).then(res => {
        const { data } = res
        this.items = data
        this.$refs['history-modal'].show()
      })
    },
    hide() {
      this.$refs['history-modal'].hide()
    }
  }
}
</script>
