<template>
  <b-modal ref="alert-modal" hide-footer title="แจ้งเตือน" no-close-on-backdrop>
    <div>
      <b-alert
        :variant="item.result ? 'success' : 'danger'"
        v-for="(item, i) in data"
        :key="`alert-account-${i}`"
        show
      >
        <h5 class="alert-heading">{{ item.title }}</h5>
        <span>{{ item.description }}</span>
      </b-alert>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      data: []
    }
  },
  watch: {
    data(val) {
      this.data = val
      if (this.data.length > 0) this.$refs['alert-modal'].show()
    }
  },
  methods: {
    show(data) {
      this.data = data
      if (this.data.length > 0) this.$refs['alert-modal'].show()
    },
    hide() {
      this.$refs['alert-modal'].hide()
    }
  }
}
</script>
