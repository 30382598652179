<template>
  <ul id="myTags" class="inverse">
    <!-- Existing list items will be pre-added to the tags -->
    <li>Tag1</li>
    <li>Tag2</li>
</ul>

</template>
<script>
export default {
  mounted() {
    $(document).ready(function() {
      $('#myTags').tagit()
    })
  }
}
</script>
