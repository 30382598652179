export default [
  {
    path: 'Packages/package',
    name: `package-manage`,
    component: () => import('@app/Packages/PackageManages'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },
  },
  {
    path: 'Packages/',
    name: `package-list`,
    component: () => import('@app/Packages/PackageBuys'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },
  },
  {
    path: 'Packages/historys',
    name: `package-history`,
    component: () => import('@app/Packages/PackageHistorys'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },
  },
  {
    path: `Packages/logs`,
    name: `package-logs`,
    component: () => import('@app/Packages/PackageLogs'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },
  },


  // {
  //   path: `Packages/Packages`,
  //   component: () => import('@app/indexNewPage.vue'),
  //   children: [

  //   ],
  // },

]
