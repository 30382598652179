<template>
  <div class="tree-menu">
    <div
      :class="selectedNode === label ? `tree-header active` : `tree-header`"
      @mouseover="showAction(true)"
      @mouseleave="showAction(false)"
    >
      <div class="padding-box" v-for="i in depth" :key="i">-</div>

      <div class="d-flex align-items-center w-100" @click="onSelect(group)">
        <div class="tree-icon" @click="toggle">
          <i :class="isExpanded ? `fa fa-angle-down` : `fa fa-angle-right`"></i>
        </div>
        <div class="tree-label">{{ label }}</div>
        <!-- <div class="d-flex ml-auto pr-2">
          <div
            v-if="max > depth + 1 && isShow"
            class="tree-icon-action"
            @click="addTopic"
          >
            เพิ่ม
          </div>
          <div
            class="tree-icon-action"
            v-if="!nodes && max > depth + 1 && isShow"
          >
            ลบ
          </div>
        </div> -->
      </div>
    </div>
    <div v-show="isExpanded && nodes !== undefined">
      <tree-menu
        v-for="(node, index) in nodes"
        :key="`sub-${index}`"
        :label="node.label"
        :nodes="node.nodes"
        :depth="depth + 1"
        :selectedNode="selectedNode"
        :onSelect="onSelect"
        :addSubTopic="addSubTopic"
        :group="[...group, node]"
      ></tree-menu>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    label: String,
    depth: Number,
    nodes: {
      default: null
    },
    group: Array,
    max: {
      type: Number,
      default: 3
    },
    selectedNode: String,
    onSelect: Function,
    addSubTopic: Function
  },
  name: 'tree-menu',
  computed: {
    styles() {
      return `
        margin-left: ${this.depth * 8}px;
        ${this.depth > 0 && `border-left: 1px solid #d9e0e7;`}
      `
    }
  },
  data() {
    return {
      isExpanded: false,
      isShow: false
    }
  },
  watch: {
    selectedNode(val) {
      if (this.label !== val) this.isShow = false
    }
  },
  methods: {
    toggle() {
      this.onSelect(this.group)
      this.isExpanded = !this.isExpanded
    },
    addTopic() {
      this.onSelect(this.group)
      this.addSubTopic(this.label)
    },
    showAction(bool) {
      if (this.selectedNode !== this.label) this.isShow = bool
    }
  }
}
</script>
<style scoped>
.tree-header {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
}
.tree-header:hover {
  background: #eeeeee;
}
.tree-header.active {
  background: #00acaa;
  color: #fff;
}
.tree-label {
  margin-left: 4px;
  font-size: 14px;
  /* width: 120px; */
}
.tree-icon {
  margin-left: 4px;
  font-size: 10px;
  width: 12px;
}
.tree-icon-action {
  margin-left: 4px;
  padding: 0 2px;
  cursor: pointer;
}
.tree-icon-action:hover {
  opacity: 0.8;
}
.padding-box {
  margin-left: 7px;
  width: 1px;
  height: 100%;
  background-color: #d9e0e7;
  color: #d9e0e7;
}
</style>
