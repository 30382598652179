
import Vue from 'vue'
const self = Vue

const masterData = () => {
  return {
    reload: {
      creditData: true
    },
    url: '/api/userData',
    selected: {
      menu: 'Credit Data',
    },
    datas: ''
  }
}

export const state = masterData()

export const mutations = {
  ADD_DATA(state, payload) {
    state.datas = payload
  }
}
export const actions = {
  async fetchData({ state, commit }, payload) {
    try {
      const { user_id } = payload
      const resp = await self.api.get(`${state.url}/${user_id}`)
      commit('ADD_DATA', resp)
      console.log(resp)
    } catch (error) { console.error(error); return "" }
  }
}