<template>
  <b-input-group size="sm">
    <b-form-input v-model="selectedDate" :state="state" autocomplete="off" :disabled="disabled" />
    <b-input-group-append is-text>
      <i class="fas fa-calendar-alt" @click="show"></i>
    </b-input-group-append>
    <div class="invalid-feedback" v-if="!state">{{ invalidFeedback }}</div>
  </b-input-group>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    format: {
      type: String,
      default: 'DD/MM/YYYY',
    },
    minDate: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    isFinanceLock: {
      type: Boolean,
      default: false,
    },
    isAccountLock: {
      type: Boolean,
      default: false,
    },
    canPickFuture: {
      type: Boolean,
      default: true,
    },
    canPickPast: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: [Boolean, null],
      default: null,
    },
    invalidFeedback: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedDate: moment().format('DD/MM/YYYY').toBETable(true), // set default selected is current date
      // selectedDate: moment().format('DD/MM/YYYY').toBETable(true), // set default selected is current date
      datetimePickrInstance: null,
    }
  },
  computed: {
    closeFinancialDate() {
      return this.$store.getters['dateSettings/getCloseFinancial']?.value
    },
    closeAccountDate() {
      return this.$store.getters['dateSettings/getCloseAccount']?.value
    },
  },
  watch: {
    selectedDate(date) {
      // console.log(date)
      this.$emit('input', date)
      // this.$emit('update:value', date)
    },
  },
  methods: {
    show() {
      $(this.$el).find('input').trigger('focus')
    },
    getInitDatetimePickerSetting() {
      return $(this.$el).find('input').datetimepicker({
        format: this.format,
        locale: 'th',
        useCurrent: true,
      })
    },
    setSelectedDateAfterDatePickerChange() {
      this.datetimePickerInstance.on('dp.change', (el) => {
        let dateInStringBE = moment(el.date).format(this.format).toBETable(true)
        // console.log(dateInStringBE)
        this.selectedDate = dateInStringBE
      })
    },
  },
  mounted() {
    this.datetimePickerInstance = this.getInitDatetimePickerSetting()
    this.setSelectedDateAfterDatePickerChange()
    if (this.value) {
      let settingDate = moment(this.value.toCETable(true), 'DD/MM/YYYY')
      this.datetimePickerInstance.data('DateTimePicker').date(settingDate) // setter
    }
    if (this.minDate) {
      let minDate = moment(this.minDate.toCETable(true), 'DD/MM/YYYY')
      this.datetimePickerInstance.data('DateTimePicker').minDate(minDate)
    }
    if (this.maxDate) {
      let maxDate = moment(this.maxDate.toCETable(true), 'DD/MM/YYYY')
      this.datetimePickerInstance.data('DateTimePicker').maxDate(maxDate)
    }
    if (!this.canPickFuture) {
      let present = moment()
      this.datetimePickerInstance.data('DateTimePicker').maxDate(present)
    }
    if (!this.canPickPast) {
      let present = moment()
      this.datetimePickerInstance.data('DateTimePicker').minDate(present)
    }
    if (this.isFinanceLock) {
      setTimeout(() => {
        let minDate = moment(this.closeFinancialDate).add(1, 'days')
        this.datetimePickerInstance.data('DateTimePicker').minDate(minDate)
        this.datetimePickerInstance.data('DateTimePicker').date(minDate)
      }, 1000)
    }
    if (this.isAccountLock) {
      setTimeout(() => {
        let minDate = moment(
          this.closeAccountDate.toCETable(true),
          'DD/MM/YYYY'
        ).add(1, 'days')
        this.datetimePickerInstance.data('DateTimePicker').minDate(minDate)
        this.datetimePickerInstance.data('DateTimePicker').date(minDate)
      }, 1000)
    }
  },
}
</script>

<style>
.input-group-sm > .form-control:not(textarea) {
  height: auto;
}
</style>
