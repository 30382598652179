<template>
  <div>
    <b-form-input
      v-model="fakeSalary"
      @focus="setHidden(true)"
      :hidden="isHidden"
      :state="state"
      :value="fakeSalary"
      :disabled="disabled"
      size="sm"
    />
    <b-form-input
      :id="id"
      :name="name"
      :ref="id"
      v-model="inputVal"
      @blur="setHidden(false)"
      @change="(val) => $emit('change', val)"
      :hidden="!isHidden"
      :state="state"
      :value="value"
      type="number"
      :min="min || 1"
      autocomplete="off"
      :disabled="disabled"
      size="sm"
    />
    <b-tooltip :target="() => $refs[id]" show>
      {{ getLocaleString(inputVal) || "กรุณากรอกข้อมูล" }}
    </b-tooltip>
    <div class="invalid-feedback" v-if="state === false">
      {{ invalidFeedback }}
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    name: String,
    validate: String,
    value: null,
    state: null,
    decimal: Boolean,
    invalidFeedback: String,
    disabled: Boolean,
    min: Number,
  },
  data() {
    return {
      inputVal: this.value,
      isHidden: false,
      fakeSalary: this.getLocaleString(this.value),
    }
  },
  mounted() {},
  watch: {
    inputVal(val) {
      this.fakeSalary = this.getLocaleString(val)
      this.$emit("input", val)
    },
    value(val) {
      this.inputVal = val
      this.fakeSalary = this.getLocaleString(val)
      this.$emit("input", val)
    },
  },
  methods: {
    setHidden(bool) {
      if (this.value) this.$emit("input", parseFloat(this.value, 10).toFixed(0))
      this.isHidden = bool
      if (bool) this.$nextTick(() => this.$refs[this.id].focus())
    },
    getLocaleString(val) {
      if (val) {
        return parseFloat(val, 10).toLocaleString() || 0
      }
      return ""
    },
  },
}
</script>
