<template>
	<b-col :md="col">
		<b-form-group
			:label-for="`type_${name}`"
			:invalid-feedback="errors.first('type')"
		>
			<label for="" class="mr-1">{{ label }}</label>
			<button
				type="button"
				class="btn btn-primary btn-sm "
				v-if="newAble"
				@click="$emit('clickNew')"
			>
				เพิ่มรายการ
			</button>
			<treeselect
				:value="select_value"
				placeholder="เลือกรายการ"
				noOptionsText="ไม่พบรายการให้เลือก"
				:multiple="true"
				:show-count="false"
				:options="opt"
				:normalizer="_custom"
				:value-consists-of="valueconsistsof"
				@input="_handleSelect"
				:sort-value-by="sortValueBy"
				:name="name"
				:disableBranchNodes="!multiple ? true : false"
				:flatten-search-results="true"
				:disabled="disabled"
			/>

			<!-- 
        แสดงข้อความ Validates
      -->
			<div
				class="alert alert-danger mt-1 col-12"
				role="alert"
				v-if="errors.items.find((f) => f.field === name)"
			>
				{{ errors.items.find((f) => f.field === name).msg }}
			</div>
		</b-form-group>
	</b-col>
</template>

<script>
/**
 *   <form-treeselect
                label="สถานที่ตั้ง *"
                url="/api/officer/management/inventory/locations/index"
                method="post"
                trackLable="name"
                trackChildren="items"
                v-model="infos.inventory_location"
                childrenName="items"
                :return-full-data="true"
                v-validate="`required`"
                data-vv-as="สถานที่ตั้ง"
                :col="6"
              />
 */
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'
import { isObject, isArray } from 'util'

export default {
	components: { Treeselect },
	props: {
		newAble: {
			type: Boolean,
			default: false,
		},
		/**
		 *
		 * @Description
		 *
		 * Flat Mode & Sort Values
		 * In all previous examples, we used the default non-flat mode of vue-treeselect, which means:
		 * Whenever a branch node gets checked, all its children will be checked too
		 * Whenever a branch node has all children checked, the branch node itself will be checked too
		 * Sometimes we don't need that mechanism, and want branch nodes & leaf nodes don't affect each other. In this case, flat mode should be used, as demonstrated in the following.
		 * If you want to control the order in which selected options to be displayed, use the sortValueBy prop. This prop has three options:
		 * "ORDER_SELECTED" (default) - Order selected
		 * "LEVEL" - Level of option: C 🡒 BB 🡒 AAA
		 * "INDEX" - Index of option: AAA 🡒 BB 🡒 C
		 */
		sortValueBy: {
			type: String,
			default: 'LEVEL',
			validator: function(val) {
				return ['ORDER_SELECTED', 'LEVEL', 'INDEX'].indexOf(val) !== -1
			},
		},

		/**
		 *
		 * @Description
		 *
		 * Prevent Value Combining
		 * For non-flat & multi-select mode, if a branch node and its all descendants are checked, vue-treeselect will combine them into a single item in the value array, as demonstrated in the following example. By using valueConsistsOf prop you can change that behavior. This prop has four options:
		 * "ALL" - Any node that is checked will be included in the value array
		 * "BRANCH_PRIORITY" (default) - If a branch node is checked, all its descendants will be excluded in the value array
		 * "LEAF_PRIORITY" - If a branch node is checked, this node itself and its branch descendants will be excluded from the value array but its leaf descendants will be included
		 * "ALL_WITH_INDETERMINATE" - Any node that is checked will be included in the value array, plus indeterminate nodes
		 */
		valueconsistsof: {
			type: String,
			default: 'ALL_WITH_INDETERMINATE',
			validator: function(val) {
				return (
					[
						'ALL',
						'BRANCH_PRIORITY',
						'LEAF_PRIORITY',
						'ALL_WITH_INDETERMINATE',
					].indexOf(val) !== -1
				)
			},
		},

		/**
		 * เอาไว้ตรวจสอบว่าจะ return data
		 * เป็นตัวเต็มหรือ ไม่
		 */
		returnFullData: {
			type: Boolean,
			defaul: false,
		},
		/**
		 * @title b-form-group
		 */
		label: {
			type: String,
			defaul: '',
		},
		/**
		 * col 1 - 12
		 */
		col: {
			type: Number,
			defaul: 12,
		},
		/**
		 * @placeholder
		 */
		pl: {
			type: String,
			defaul: 'กรุณาเลือก',
		},
		/**
		 * กำหนดเปิดปิด select
		 */
		disabled: {
			type: Boolean,
			default: false,
		},

		/**
     * @Doc :: https://vue-treeselect.js.org/#basic-features
     *@Array
     * รูปแบบข้อมูลที่ต้องการ
     * [
     *   { id: 'catalog', text: 'ชั้นที่ 1' },
     *   { id: 'topic', text: 'ชั้นที่ 2',
     *      @children คือ เพิ่มตัวเลือกลงไปเป็นชั้นๆ
     *       children: [
     *          code: '80100x02',
                  id: '5C87EAF7-C355-485A-80EF-A5F46B45B83q',
                  code_ref: '8c00e000',
                  value: '801c000',
                  text: 'ผู้ตรวจxcสอeบกิจการ',
     *       ]
     *   },
     * ]
     * 
     */
		options: {
			type: Array,
			default: function() {
				return []
			},
		},
		/**
		 * @note
		 * สามารถเลือก หลายอันได้หรือไม่
		 */
		multiple: {
			type: Boolean,
			default: false,
		},
		/**
		 * @Custom
		 * Lable ที่ต้องการให้แสดง defaul
		 *
		 */
		normalizer: {
			type: Function,
			// default: function(node) {
			// 	return {
			// 		id: node.id,
			// 		label: node.label
			// 		// children: node.subOptions
			// 	}
			// }
		},
		trackLable: {
			type: String,
			default: 'label',
		},
		trackChildren: {
			type: String,
			default: 'children',
		},

		/**
		 * @note
		 * เอาไว้กำหนดการตัดค่าว่างออกจาก array
		 * Example items:[]
		 * ต้องส่ง items มาเพื่อตัดค่าว่างออก
		 */
		childrenName: {
			type: String,
			default: 'children',
		},
		sizeUUID: {
			type: Number,
			default: 36,
		},
		value: {
			type: null,
		},
		url: {
			type: String,
			default: '',
		},
		method: {
			type: String,
			validator: function(value) {
				return ['post', 'get'].indexOf(value) !== -1
			},
		},
	},
	mounted() {
		this.getItem(this.url)
	},
	watch: {
		options: function(val) {
			this.opt = this.splitChildrenNull(val)
		},
		value: {
			handler: function(val) {
				try {
					/**
					 * Clear value
					 */
					if (val.length === 0) {
						this.select_value = []
						return ''
					}

					/**
					 * Set old value
					 */
					this.select_value = val
					return ''
				} catch (error) {
					this.select_value = []
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			datas: [],
			name: 'name' + Math.floor(Math.random() * (0 - 99 + 999)) + 1,
			select_value: isArray(this.value) ? this.value : [],
			saveValue: [],
			checkEmit: true,
			opt: this.splitChildrenNull(this.options),
			custom: '',
		}
	},

	methods: {
		/**
		 * @param uuid ของตัวที่เลือก
		 * @return Object
		 */
		shareFunctionFindData(id) {
			const options = this.opt
			return this.findFullData(options, id)
		},
		/**
		 * หาข้อมูลเต็ม ของการเลือกตัวเดียว
		 */
		async findFullData(data, id) {
			const self = this
			const iter = (a) => {
				if (a.id === id) {
					result = a
					return true
				}
				const trackChildren = this.trackChildren

				return (
					Array.isArray(a[self.trackChildren]) &&
					a[self.trackChildren].some(iter)
				)
			}

			var result
			data.some(iter)
			return result
		},
		async getItem(url) {
			try {
				if (url) {
					const method = this.method
					let resp = ''
					if (method === 'post') {
						resp = await this.api.post(url, {})
					} else if (method === 'get') {
						resp = await this.api.get(url)
					}
					// if (typeof resp == 'object') {
					// 	resp = [resp]
					// }

					this.opt = this.splitChildrenNull(resp)

					// this.select_value = this.value
				} else {
					// this.select_value = this.value
				}

				return ''
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		splitChildrenNull(values) {
			try {
				// console.log('start split')
				let convert = JSON.stringify(values)
				// switch (this.childrenName) {
				//   case 'children':
				//     convert = convert.replace(/\,\"children\"\:\[\]/g, '')
				//     break
				//   case 'items':
				//     convert = convert.replace(/\,\"items\"\:\[\]/g, '')
				//     break

				//   default:
				//     break
				// }

				convert = convert
					.replace(/\,\"children\"\:\[\]/g, '')
					.replace(/\,\"items\"\:\[\]/g, '')

				convert = JSON.parse(convert)
				return convert
			} catch (error) {}
		},
		/**
		 * @param data = array ที่ต้องการค้นห้า
		 * @param id = รหัสที่ต้องการหา
		 */
		async findById(data, id) {
			const iter = (a) => {
				if (a.id === id) {
					result = a
					return true
				}
				return Array.isArray(a.children) && a.children.some(iter)
			}

			var result
			data.some(iter)
			return result
		},
		async returnEmit(val) {
			try {
				if (!val.length) {
					this.$emit('input', [])
					return ''
				}
				if (!this.returnFullData) {
					this.$emit('input', val)
				} else {
					if (val.length > 1) {
						this.alertWarning(
							'form tree select prop returnFullData ใช้ได้กับ single เท่านั้น'
						)
						return ''
					}
					const _return_full_data = await this.findFullData(this.opt, val[0])
					this.$emit('input', _return_full_data)
				}
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		_handleSelect(val) {
			if (this.multiple) {
				this.returnEmit(val)
				return ''
			}

			// if เดิม
			// if (this.saveValue.length >= 0) {
			if (this.saveValue.length > 0) {
				this.checkValue(val)
				return ''
			} else {
				this.checkMax(val)

				this.saveValue = val
				this.returnEmit(val)
				return ''
			}
		},
		async checkValue(useSelect) {
			try {
				let lastValue = this.saveValue[this.saveValue.length - 1]
				const index = await useSelect.findIndex((FI) => FI === lastValue)
				if (index != -1) {
					/**
					 * @note
					 * ทำงานตอนเลือกเป็น 1 - 1
					 * จะทำการยกเลิกตัวเก่าแล้วเลือก ตัวใหม่แทน
					 */
					useSelect.splice(index, 1)

					this.select_value = useSelect

					this.returnEmit(useSelect)
					this.saveValue = []
				} else {
					/**
					 * ทำงานเมื่อเลือก ครั้งแรก
					 */
					const newOptions = this.options.length ? this.options : this.opt
					let arrayID = []
					let datas = []
					for (let index = 0; index < useSelect.length; index++) {
						const id = useSelect[index]
						const findId = await this.findById(newOptions, id)

						if (findId) {
							let { id, name, code, children } = findId
							arrayID.push(id)
							datas.push({ id, name, code })
							this.datas = findId
							if (!children) {
								break
							}
						}
					}

					this.saveValue = arrayID
					this.select_value = arrayID
					this.returnEmit(datas)
				}
			} catch (error) {
				this.returnEmit([])
				return ''
			}
		},
		async checkMax(value) {
			try {
				const max = this.sizeUUID
				let count = 0
				let status = true
				let firstData = 0
				for (let index = 0; index < value.length; index++) {
					const el = value[index]
					if (el.length === max) {
						count++
						if (count === 1) firstData = index
						if (count === 2) {
							status = 0
							break
						}
					}
				}
				if (!status) {
					value.splice(firstData + 1, value.length)
					this.select_value = value
				} else {
					this.select_value = value
				}
			} catch (error) {
				console.error(error)
				return ''
			}
		},
	},
	computed: {
		_custom() {
			// this.normalizer
			// debugger
			if (this.normalizer) {
				return this.normalizer
			} else {
				let self = this
				// self.childrenName = self.trackChildren
				return function(item) {
					return {
						id: item.id,
						label: item[self.trackLable],
						children: item[self.trackChildren],
					}
				}
			}
		},
	},
}
</script>
