<template>
  <section>
    <b-row>
      <b-col sm="6">
        <h3>{{ title }}</h3>
      </b-col>
      <!-- <b-col sm="6" class="text-right">
        <btn-cancel text="กลับหน้าหลัก" clickGotoPage="main-menu-meter-index" />
      </b-col> -->
    </b-row>

    <b-card :col="col" class="mt-4">
      <slot></slot>
    </b-card>
  </section>
</template>

<script>
import BCardCode from "@core/components/b-card-code"

export default {
  props: {
    title: String,
    col: {
      type: Number,
      default: 12,
    },
  },
  components: {
    BCardCode,
  },
}
</script>
