<template>
  <b-col :sm="col">
    <fieldset class="groupbox-border">
      <legend class="groupbox-border">
        <h3>{{ title }}</h3>
      </legend>
      <div class="control-group">
        <slot name="body"></slot>
      </div>
    </fieldset>
  </b-col>
</template>
<script>
export default {
	props: {
		col: {
			type: [Number, String],
			default: 12,
		},
		title: {
			type: String,
			default: '',
		},
	},
}
</script>
<style scoped>
.tree {
	height: 450px;
	overflow-y: scroll;
}
.tree::-webkit-scrollbar {
	width: 6px;
	border-radius: 8px;
}
.tree::-webkit-scrollbar-thumb {
	background-color: #d9e0e7;
}

.explorer {
	width: 100%;
	border-right: 1px solid #ccc;
	padding-right: 16px;
}

fieldset.groupbox-border {
	border: 1px groove #ddd !important;
	padding: 0 1.4em 1.4em 1.4em !important;
	margin: 1.2em 1.2em 1.2em 1.2em !important;
	-webkit-box-shadow: 0px 0px 0px 0px #000;
	box-shadow: 0px 0px 0px 0px #000;
}

legend.groupbox-border {
	font-size: 1.2em !important;
	font-weight: bold !important;
	text-align: left !important;
	width: auto;
	padding: 0px 10px 0px 10px;
	border-bottom: none;
}
</style>
