<template>
  <b-row>
    <b-col sm="12" v-html="withCommas(_value)" v-if="!action" />
    <form-input-currency
      v-else
      :label="text"
      v-model="inputValue"
      v-validate="action ? `required` : ''"
      data-vv-as="กรุณากรอก ข้อมูล"
      :col="12"
    />
  </b-row>
</template>
<script>
export default {
	props: {
		text: '',
		value: null,
		action: {
			type: Boolean,
			default: false
		}
	},
	watch: {
		value(val) {
			this.inputValue = val
		},
		inputValue(val) {
			this.$emit('callback', val)
		}
	},
	computed: {
		_value() {
			this.$emit('callback', this.value)
			return this.value
		}
	},
	data() {
		return {
			inputValue: this.value
		}
	}
}
</script>