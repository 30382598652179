import Vue from 'vue'

// axios
import axios from 'axios'
const domain = process.env.VUE_APP_API_BASEURL
const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: domain,
  timeout: 2000,
  headers: {
    'X-Custom-Header': 'foobar',
    // "content-type": "multipart/form-data",
  },
})


// axios.defaults.headers = { 'X-Custom-Header': 'foobar' }
axiosIns.interceptors.response.use(response => {
  return response
}, error => {
  if (error.response.status === 401) {
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('userData')
    location.reload()
  } else if (error.response.status === 403) {
    const spUrl = (document.URL).split('/')
    if (spUrl[spUrl.length - 1] != 'emailVerifyNoti') {
      const url = "localhost:8082"//process.env.VUE_APP_API_BASEURL
      console.log(`${url}/emailVerifyNoti`)
      window.location.href = `/emailVerifyNoti`
    }
    // return Promise.resolve(error)

  }
  return Promise.reject(error)
})

Vue.prototype.$http = axiosIns

export default axiosIns
