<template>
	<img
		src="https://www.gravatar.com/avatar/205e460b479e2e5b48aec07710c08d.jpg?s=60"
	/>
</template>

<script>
export default {
	props: {
		data: {},
	},
}
</script>
