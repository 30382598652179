<template>
  <panel title=" ประวัติผู้ค้ำประกัน" bordered>
    <b-row>
      <b-col sm="12">
        <b-tabs pills>
          <b-tab
            v-for="(item, index) in _tabs"
            :key="index"
            :title="item.title"
            :active="index === 0 ? true : false"
          >
            <component :is="item.component" :items="_items" :title="item.title" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </panel>
</template>

<script>
export default {
	props: {
		memberOrHrId: String,
	},
	data() {
		return {
			items: [],
		}
	},
	watch: {
		memberOrHrId(val) {
			this.queryData(val)
		},
	},
	computed: {
		_items() {
			return this.items ? this.items : []
		},
		_tabs() {
			return [
				{
					title: 'เคยไปค้ำสัญญา',
					component: () => import('./cuaranteeHistory/listOfGuarantee'),
				},
				{
					title: 'รายการคนค้ำ',
					component: () =>
						import('./cuaranteeHistory/listOfGuaranteeToLoanData'),
				},
			]
		},
	},
	methods: {
		async queryData(member_or_hr_id) {
			try {
				if (member_or_hr_id) {
					this.items = await this.api.get(
						`/api/officer/management/loans/loancollateral/guaranteehistory/${member_or_hr_id}`
					)
					return ''
				}
				this.items = []
			} catch (error) {
				console.error(error)
				return false
			}
		},
	},
}
</script>