<template>
  <section>
    <div class="vld-parent">
      <loading :active.sync="loaded" :can-cancel="true" :is-full-page="true">
      </loading>
    </div>
    <b-col :sm="col" ref="paypal" :id="name" :name="name"></b-col>
  </section>
</template>

<script>
import Loading from "vue-loading-overlay"
import "vue-loading-overlay/dist/vue-loading.css"
export default {
  components: {
    Loading,
  },
  props: {
    col: {
      type: Number,
      default: 12,
    },
    totalPrice: {
      type: Number,
      default: 0.1,
    },
    description: {
      type: String,
      default: "Buy Package",
    },
    currencyCode: {
      type: String,
      default: "USD",
    },
  },
  data() {
    return {
      name:
        `name_` + Math.floor(Math.random() * (0 - 999999 + 999999999999)) + 1,
      loaded: false,
      paidFor: false,
    }
  },
  mounted() {
    const script = document.createElement("script")
    script.src = `https://www.paypal.com/sdk/js?client-id=${process.env.VUE_APP_PAYPAL_CLIENT_ID}`

    script.addEventListener("load", this.setLoaded)
    document.body.appendChild(script)
  },
  created() {},
  methods: {
    async setLoaded() {
      try {
        this.$emit("loaded", true)
        this.loaded = true
        console.log(`start`)
        await window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    description: this.description,
                    amount: {
                      currency_code: this.currencyCode,
                      value: this.totalPrice,
                    },
                  },
                ],
              })
            },
            onApprove: async (data, actions) => {
              const order = await actions.order.capture()
              this.paidFor = true
              this.$emit("paypal-callback", order)
            },
            onError: (err) => {
              this.$emit("paypal-callback", null)
            },
          })
          .render(this.$refs.paypal)
        this.loaded = false
        this.$emit("loaded", false)
      } catch (error) {
        console.error(error)
        return ""
      }
    },
  },
}
</script>
