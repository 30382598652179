<template>
    <div>
        <b-form-input
            v-model="inputVal"
            ref="select-searchable"
            :list="`${this.id}-list`"
            @focus="clearInput"
            @blur="checkInput"
            :disabled="disabled || false"
            :state="state"
        />
        <datalist :id="`${this.id}-list`" v-if="options.length > 0" :value="value">
            <option :key="item.value" v-for="item in options">{{ item.text }}</option>
        </datalist>
        <b-form-input
            v-model="selected"
            :id="id"
            :name="name"
            :list="`${this.id}-list`"
            :disabled="disabled || false"
            :state="state"
            hidden
        />
        <div class="invalid-feedback" v-if="state === false">{{ invalidFeedback }}</div>
    </div>
</template>

<script>
export default {
	props: {
		name: String,
		id: String,
		options: Array,
		value: null,
		disabled: Boolean,
		placeholder: String,
		invalidFeedback: String,
		state: null
	},
	data() {
		return {
			inputVal: null,
			selected: this.value,
			history: this.value
		}
	},
	mounted() {
		if (this.value) {
			const obj = this.options.find(item => item.value === this.value)
			this.inputVal = obj !== undefined ? obj.text : null
			this.history = obj !== undefined ? obj.text : null
		}
	},
	beforeUpdate() {
		if (this.value) {
			this.selected = this.value
			const obj = this.options.find(item => item.value === this.value)
			this.inputVal = obj !== undefined ? obj.text : null
			this.history = obj !== undefined ? obj.text : null
		}
	},
	watch: {
		inputVal(value) {
			const obj = this.options.find(item => item.text === value)
			this.selected = obj !== undefined ? obj.value : null
		},
		selected(value) {
			this.$emit('input', value)
			if (value) this.$nextTick(() => this.$refs['select-searchable'].blur())
		},
		value(value) {
			if (!value) {
				this.selected = null
				this.inputVal = null
			}
		}
	},
	methods: {
		checkInput() {
			if (this.history) {
				this.inputVal = this.history
			} else {
				const obj = this.options.find(item => item.text === this.inputVal)
				this.inputVal = obj !== undefined ? obj.text : null
			}
		},
		clearInput() {
			if (this.state === false) {
				this.selected = null
				this.inputVal = null
				this.history = null
			}
			this.$emit('input', null)
		}
	}
}
</script>
