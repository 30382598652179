<template>
  <b-row>
    <b-col md="6">
      <DatePicker
        ref="start"
        :end-ref="$refs.end"
        @input="val => inputDate(1, val)"
      />
    </b-col>
    <b-col md="6">
      <DatePicker
        ref="end"
        :start-ref="$refs.start"
        @input="val => inputDate(2, val)"
      />
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: ['value'],
  data() {
    return {
      inputVal: '',
      refStart: '',
      refEnd: ''
    }
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.inputVal = val
    }
  },
  methods: {
    inputDate(mode, val) {
      const oldVal = this.inputVal.split('-')
      if (mode === 1) {
        this.inputVal = val + '-' + oldVal[1]
      } else {
        this.inputVal = oldVal[0] + '-' + val
      }
    }
  }
}
</script>

<style></style>
