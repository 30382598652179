import Vue from 'vue'
const self = Vue
// axios
import axios from '@/libs/axios'
// const domain = process.env.VUE_APP_API_BASEURL
// const api = axios.create({
// 	baseURL: domain,
// 	// domain
// 	// You can add your headers here
// })

export default function (Vue) {
  Vue.api = {
    async get(url) {
      try {
        let {
          data: {
            data
          }
        } = await axios.get(url)
        return Promise.resolve(data)
      } catch (error) {
        // if (error?.response?.headers?.requestid) {
        //     $.gritter.add({
        //         title: error?.response?.headers?.requestid,
        //         text: url,
        //         time: null,
        //         class_name: 'my-sticky-class'
        //     })
        // }
        console.error(error)
        return Promise.reject(error)
      }
    },
    /**
     * ทำใหม่แบ่บไม่ตัด data ออก ให้ return เต็ม
     * @param {String} url ไม่ตัด Data
     */
    async _get(url) {
      try {
        const data = await axios.get(url)
        return Promise.resolve(data)
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        console.error(error)
        return Promise.reject(error)
      }
    },
    async post(url, request) {
      try {
        // let redule = coppyParam(request)
        // await checkDate(data)

        let {
          data: {
            data,
            code,
            errors
          }
        } = await axios.post(url, request)


        if (code === 200 || code === 201 || code === 204) {
          return Promise.resolve(data)
        } else {
          return Promise.reject(data)
        }
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        return Promise.reject(error)
      }
    },
    async patch(url, request) {
      try {
        let {
          data: {
            data
          }
        } = await axios.patch(url, request)
        return Promise.resolve(data)
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        return Promise.reject(error)
      }
    },
    async put(url, request) {
      // every method need to return status code to handle error each scenarios
      try {
        // let redule = coppyParam(request)
        // await checkDate(data)
        let {
          data: {
            data,
            code
          }
        } = await axios.put(url, request)
        return Promise.resolve(data)
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        return Promise.reject(error)
      }
    },
    async del(url, request, confirmDelete = false) {
      try {
        /**
         * Overwrite data
         */
        request = { params: request }

        if (confirmDelete) {
          const result = await Vue.swal({
            title: 'are you sure want to delete',
            text: '',
            icon: 'warning',

            showCancelButton: true,
            confirmButtonText: 'Confirm',
            cancelButtonText: 'Cancel',
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          /**
           * If Confirm Delete data
           */
          if (result.value) {
            let {
              data: {
                data,
                errors
              }
            } = await axios.delete(url, request)
            await Vue.swal({
              icon: 'success',
              title: 'Successfully deleted',
              // text: 'Your file has been deleted.',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            return Promise.resolve(data)
          } else {
            return Promise.resolve(data)
          }
        }
        let {
          data: {
            data,
            errors
          }
        } = await axios.delete(url, request)
        return Promise.resolve(data)
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        console.error(error)

        return Promise.reject(error)
      }
    },
    async createOrUpdate(url, form, popupMessage = '', fillCheck = 'id') {
      try {
        let resources = ''
        if (!form.id) {
          resources = await axios.post(url, form
            // , {
            // headers: {
            //   "content-type": "multipart/form-data",
            //   "Content-Type": "multipart/form-data"
            // }
            // }
          )
        } else {
          const formId = form[fillCheck]
          /**
           * ตรวจสอบว่ามี / มาหรือไม่
           */
          if (!(url.slice(-1) === '/')) {
            url = url + '/' + formId
          } else {
            url = url + formId
          }

          resources = await axios.patch(url, form,
            //   {
            //   headers: {
            //     // "content-type": "multipart/form-data",
            //     // "Content-Type": "multipart/form-data"
            //   }
            // }
          )
        }


        if (resources.data.errors) {
          self.alert.validator({ title: "ระบบ", text: resources.data.data })
          return Promise.reject(resources)
        }

        if (popupMessage) {
          if (form[fillCheck]) {
            popupMessage = `Update ${popupMessage}`
          } else {
            popupMessage = `Create ${popupMessage}`
          }
          Vue.alert.respCreateOrUpdate(resources, popupMessage)
        }
        return resources.data.data
      } catch (error) {
        if (error.response.status === 422) {
          Vue.alert.errorCode422(error.response.data)
        }

        return Promise.reject(error.response.data)
      }
    },
    async download(url, data, file = {
      name: 'file.xls'
    }) {
      try {
        axios({
          url,
          method: 'POST',
          responseType: 'blob',
          data
        }).then(response => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]))
          var fileLink = document.createElement('a')
          fileLink.href = fileURL
          fileLink.setAttribute('download', 'file.xls')
          document.body.appendChild(fileLink)
          fileLink.click()
        })
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        console.error(error)
        return ''
      }
    },
    async downloadPDF(url, payload, fileName) {
      try {
        axios({
          url: url,
          method: 'POST',
          responseType: 'blob', // important
          data: payload
        }).then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', `${fileName}.pdf`)
          document.body.appendChild(link)
          link.click()
        })
      } catch (error) {
        if (error?.response?.headers?.requestid) {
          $.gritter.add({
            title: error?.response?.headers?.requestid,
            text: url,
            time: null,
            class_name: 'my-sticky-class'
          })
        }
        console.log(error)
      }
    }
  }

  Object.defineProperties(Vue.prototype, {
    api: {
      get() {
        return Vue.api
      }
    }
  })
}