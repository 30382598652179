const meta = {
  meta: {
    resource: 'ACL',
    action: 'client',
  }
}
export default [
  {
    path: 'TollFreeVoices/Setting',
    name: `call-Setting`,
    component: () => import('@app/TollFreeVoices/CallSettings'),
    ...meta

  },
  {
    path: 'TollFreeVoices/HacsIntegration',
    name: `call-HacsIntegration`,
    component: () => import('@app/TollFreeVoices/CallHacsIntegration'),
    ...meta
  },
  {
    path: 'TollFreeVoices/NodeRed',
    name: `call-NodeRed`,
    component: () => import('@app/TollFreeVoices/CallNodeReds'),
    ...meta
  },
  {
    path: 'TollFreeVoices/Automation',
    name: `call-Automation`,
    component: () => import('@app/TollFreeVoices/CallAutomation'),
    ...meta
  },
]
