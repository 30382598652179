<template>
	<b-col sm="12" class="mb-1">
		<!-- use component -->
		<Form-modal-add-phone
			ref="formModalAddPhone"
			@callBackFormModal="callBackFormModal"
		/>
		<!-- end use components -->

		<DataTableLocal
			:view-able="false"
			:columns="_column"
			:items="form"
			@clickNew="clickNew"
			@clickEdit="clickEdit"
			@clickDelete="clickDelete"
		/>
	</b-col>
</template>

<script>
export default {
	components: {
		FormModalAddPhone: () => import('./componentPhone/modalAddPhone'),
	},
	computed: {
		_column() {
			const columns = [
				{
					label: 'เบอร์โทร',
					field: 'phone',
				},
				{
					label: 'รายละเอียด',
					field: 'note',
				},
			]
			return columns
		},
	},
	props: {
		value: null,
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (!val) return ''
				this.form = val
			},
		},
	},
	data() {
		return {
			form: [],
		}
	},
	methods: {
		handelCallBack() {
			const form = this.convert.cp(this.form)
			this.$emit('input', form)
		},
		clickNew() {
			this.$refs.formModalAddPhone.show()
		},
		clickEdit(data) {
			const _data = this.convert.cp(data)
			this.$refs.formModalAddPhone.show(_data)
		},

		clickDelete(data) {
			this.form.splice(data.originalIndex, 1)
			this.handelCallBack()
		},

		callBackFormModal(data) {
			/**
			 * @IF action update data
			 * @else action create data
			 */

			if (typeof data.originalIndex === 'number') {
				this.form[data.originalIndex] = data
			} else {
				this.form.push(data)
			}
			this.handelCallBack()
		},
	},
}
</script>
