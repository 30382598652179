<template>
  <panel :title="_title" bordered :variant="variant">
    <form-modal :title="_title_modal" ref="modalrecive" @save="add">
      <b-row>
        <!-- ช่องทาง -->
        <form-select
          label="ช่องทาง *"
          :options="ways"
          v-model="data.type"
          track-lable="text"
          data-vv-as="ช่องทาง"
          v-validate="'required'"
          :col="4"
        />

        <!-- จำนวนเงิน -->
        <b-col md="4">
          <b-form-group label="จำนวนเงิน *" label-for="amount">
            <currency-input
              id="amount"
              name="amount"
              :decimal="true"
              v-model="data.amount"
              data-vv-as="จำนวนเงิน"
              v-validate="'required|decimal'"
              :state="getFormState(inputFields, 'amount', errors)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
            label="วันที่ดำเนินการ *"
            label-for="receipt_date"
            :invalid-feedback="errors.first('receipt_date')"
          >
            <DatePicker
              id="receipt_date"
              name="receipt_date"
              v-model="data.receipt_date"
              data-vv-as="วันที่ดำเนินการ"
              v-validate="'required|date_format:dd/MM/yyyy'"
              :lockDate="lastestAccountCloseDate"
              :state="getFormState(inputFields, 'receipt_date', errors)"
              :invalid-feedback="errors.first('receipt_date')"
              now
            />
          </b-form-group>
        </b-col>
      </b-row>

      <!-- ธนาคาร -->
      <b-row v-if="data.type === 'โอน' || data.type === 'เช็ค'">
        <form-select
          v-if="type == 'รายจ่าย'"
          label="ธนาคาร *"
          :options="options.banks"
          v-model="data.bank_no"
          data-vv-as="ธนาคาร"
          v-validate="'required'"
          :col="8"
          track-lable="text"
        />
        <form-input
          v-else
          label="ธนาคาร *"
          v-model="data.bank_name"
          data-vv-as="ธนาคาร"
          v-validate="'required'"
          :col="8"
        />

        <!-- หมายเลขบัญชี -->
        <b-col md="4" v-if="data.type === 'โอน'">
          <b-form-group
            label="หมายเลขบัญชี *"
            label-for="account_no"
            :invalid-feedback="errors.first('account_no')"
          >
            <b-form-input
              id="account_no"
              name="account_no"
              v-model="data.account_no"
              data-vv-as="หมายเลขบัญชี"
              v-validate="'required'"
              :state="getFormState(inputFields, 'account_no', errors)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4" v-if="data.type === 'เช็ค'">
          <b-form-group
            label="เลขที่เช็ค *"
            label-for="cheque_no"
            :invalid-feedback="errors.first('cheque_no')"
          >
            <b-form-input
              id="cheque_no"
              name="cheque_no"
              v-model="data.cheque_no"
              data-vv-as="เลขที่เช็ค"
              v-validate="'required'"
              :state="getFormState(inputFields, 'cheque_no', errors)"
            />
          </b-form-group>
        </b-col>

        <!-- ชื่อบัญชี -->
        <b-col md="6" v-if="data.type === 'โอน' || data.type === 'เช็ค'">
          <b-form-group
            label="ชื่อบัญชี *"
            label-for="account_name"
            :invalid-feedback="errors.first('account_name')"
          >
            <b-form-input
              id="account_name"
              name="account_name"
              v-model="data.account_name"
              data-vv-as="ชื่อบัญชี"
              v-validate="''"
              :state="getFormState(inputFields, 'account_name', errors)"
            />
          </b-form-group>
        </b-col>

        <!-- หมายเหตุ -->
        <b-col md="6">
          <b-form-group
            label="หมายเหตุ *"
            label-for="note"
            :invalid-feedback="errors.first('note')"
          >
            <b-form-textarea
              id="note"
              name="note"
              v-model="data.note"
              data-vv-as="หมายเหตุ"
              v-validate="''"
              :rows="2"
              :state="getFormState(inputFields, 'note', errors)"
            ></b-form-textarea>
          </b-form-group>
        </b-col>

        <!-- เลขที่อ้างถึง -->
        <b-col md="4">
          <b-form-group
            label="เลขที่อ้างถึง *"
            label-for="ref_no"
            :invalid-feedback="errors.first('ref_no')"
          >
            <b-form-input
              id="ref_no"
              name="ref_no"
              v-model="data.ref_no"
              data-vv-as="เลขที่อ้างถึง"
              v-validate="''"
              :state="getFormState(inputFields, 'ref_no', errors)"
            />
          </b-form-group>
        </b-col>
      </b-row>

      <AttachedDeposit />
    </form-modal>

    <!-- 
      Tables
    -->
    <b-row>
      <b-col md="12">
        <b-button variant="secondary" @click="openModal()" class="mr-1">เพิ่ม{{ _title }}</b-button>
      </b-col>
    </b-row>
    <br />

    <b-row>
      <b-col sm="12">
        <form-field-set :title="_list">
          <template slot="body">
            <table class="table table-bordered table-striped">
              <thead>
                <tr>
                  <th class="text-center">ช่องทาง</th>
                  <th class="text-center">จำนวนเงิน</th>
                  <th class="text-center">วันที่ดำเนินการ</th>
                  <th class="text-center">รายละเอียด</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(item, index) in item" :key="index">
                  <td>{{ item.type }}</td>
                  <td class="text-right">{{ withCommas(item.amount) }}</td>
                  <td class="text-right">{{ item.pay_date }}</td>
                  <td>{{ item.detail }}</td>
                  <td class="text-center">
                    <button class="btn btn-sm btn-danger" @click="removeRow(index)">ลบ</button>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr></tr>
              </tfoot>
            </table>
          </template>
        </form-field-set>
      </b-col>

      <b-col md="12" sm="3">
        <form-field-set title="ยอดเงินรวม">
          <template slot="body" class="text-right">
            <h4>{{ withCommas(calculateAmount) }}</h4>
          </template>
        </form-field-set>
      </b-col>
    </b-row>
  </panel>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
	props: {
		/**
		 * กำหนด ว่าจะให้ใช้ตัวไหนได้บ้างเช่น
		 * ['เงินสด'] คือให้ใช้แค่เงินสดถ้าไม่ใส่ จะให้เลือกทั้งหมด
		 */
		only: {
			type: Array,
		},
		except: {
			type: Array,
		},
		onlyWays: {
			type: Array,
		},
		type: {
			type: String,
			validator: function (value) {
				return ['รายรับ', 'รายจ่าย', 'อื่นๆ'].indexOf(value) !== -1
			},
			default: 'รายรับ',
		},
		dataID: {
			type: String,
			default: '',
		},
		tablename: {
			type: String,
			default: 'table-deposit-amount',
		},
		variant: {
			type: String,
			default: 'info',
		},
	},
	computed: {
		lastestAccountCloseDate() {
			return this.$store.getters['dateSettings/getCloseAccount']?.value
		},
		_title() {
			let type = this.type
			if (type === 'รายรับ') {
				return 'ช่องทางการรับเงิน'
			} else if (type === 'รายจ่าย') {
				return 'ช่องทางการจ่ายเงิน'
			}
			return 'ช่องทางอื่นๆ'
		},
		_list() {
			let type = this.type
			if (type === 'รายรับ') {
				return 'รายการรับเงิน'
			} else if (type === 'รายจ่าย') {
				return 'รายการจ่ายเงิน'
			}
			return 'รายการอื่นๆ'
		},
		_title_modal() {
			let type = this.type
			if (type === 'รายรับ') {
				return 'รายละเอียดรับเงิน'
			} else if (type === 'รายจ่าย') {
				return 'รายละเอียดจ่ายเงิน'
			}
			return 'รายละเอียดอื่นๆ'
		},
		/**
		 * คำนวนยอดเงินรวมใน Table
		 * @return ยอดเงินรวมใน table
		 */
		calculateAmount() {
			let cal = this.item.reduce((previous, current) => {
				return previous + current.amount * 1
			}, 0)
			/**
			 * @emit ส่งค่ากลับออกไปเพื่อใช้งานต่อไปนอก component
			 */
			this.$emit('calculateAmount', cal)
			return cal
		},
		ways() {
			return this.options.type.filter((item) => {
				if (this.only) {
					return this.only.includes(item.text)
				} else if (this.except) {
					return !this.except.includes(item.text)
				}
				return true
			})
		},
		...mapState(['master']),
	},
	watch: {
		'data.type'() {
			console.log(this.data.type.value)
			return ''
			let map = this.onlyWays.find((f) => {
				if (f.charAt(1) === this.data.type.value) {
					return { f }
				}
			})
			this.getW(map)
		},
		'data.bank_no'(value) {
			this.data.account_no = value.account_no
		},
		// 'data.type'(value) {
		//   if (!isNull(value)) {
		//     console.log(value)
		//     console.log(this.dataID + ' ' + this.tablename)
		//   }
		// }
	},
	data() {
		return {
			ways_code: '',
			receipt_date: '',
			data: {
				ref: '',
				table: '',
				type: '',
				amount: '',
				pay_date: '',
				bank_no: '',
				bank_name: '',
				cheque_no: '',
				account_no: '',
				account_name: '',
				note: '',
				detail: '',
				payment_id: '',
			},
			post_data: {
				type: '',
				receipt_date: '',
				amount: '',
				ref_no: '',
				note: '',
				items: [],
			},
			items_data: {
				receipt_date: '',
				payment_code: '',
				payment_name: '',
				type: '',
				ref: '',
				table: '',
				description: '',
				quantity: '',
				unit_price: '',
				amount: '',
				action: '',
				note: '',
			},
			data_send: {
				payment_id: '',
				amount: '',
			},
			options: {
				type: [
					{ value: '1', text: 'เงินสด' },
					{ value: '2', text: 'เช็ค' },
					{ value: '3', text: 'โอน' },
				],
				banks: [
					{ value: 'KTB', text: 'ธนาคารกรุงไทย' },
					{ value: 'TMB', text: 'ธนาคารทหารไทย' },
					{ value: 'kbank', text: 'ธนาคารกสิกรไทย' },
				],
			},
			errorMessage: '',
			field: {
				type: {
					label: 'ช่องทาง',
				},
				amount: {
					label: 'จำนวนเงิน',
				},
				pay_date: {
					label: 'วันที่ดำเนินการ',
				},
				detail: {
					label: 'รายละเอียด',
				},
			},
			item: [],
			id_transfer: this.d,
		}
	},
	mounted() {
		// this.getMasterData(['bank'])
		// this.options.banks = this.master.bank
		// this.get(`/api/officer/getbank`).then(({ data }) => {
		//   this.options.banks = data
		// })

		this.get(`/api/payment/getFromList`).then(({ data }) => {
			this.options.banks = data
		})
	},
	methods: {
		...mapActions('master', ['getMasterData']),
		async getW(way_code) {
			try {
				// const {data:{data}} = await this.get(`/api/payment/getWay/${way_code}`)
				// console.log(data)
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		openModal() {
			this.$refs.modalrecive.show()
		},
		removeRow(index) {
			this.item.splice(index, 1)
		},
		add() {
			this.$validator.validate().then((result) => {
				if (result) {
					this.data.type = this.data.type.text
					this.data.ref = this.dataID
					this.data.table = this.tablename
					this.receipt_date = this.data.pay_date
					if (this.data.type === 'เช็ค') {
						this.data.detail =
							this.data.bank_name + ' เลขที่เช็ค' + this.data.cheque_no
					} else if (this.data.type === 'โอน') {
						this.data.detail =
							this.data.bank_name + ' เลขที่บัญชี' + this.data.account_no
					}
					this.item.push({
						...this.data,
						payment_code: '11100-020101001',
						payment_name: 'รับเงินสด สำหรับ ออมทรัพย์',
					})
					this.clearData()
					this.$refs.modalrecive.hide()
				} else {
					this.errorMessage = 'กรุณากรอกข้อมูลให้ครบถ้วน'
				}
			})
		},
		del(data, index) {
			this.item.splice(index, 1)
		},
		submit() {
			if (this.item.length > 0) {
				return this.post(`/api/payment/`, {
					ref_no: this.data.ref_no,
					type: 'รายรับ',
					amount: this.calculateAmount,
					items: this.item,
				})
			} else {
				return false
			}
		},
		clearData() {
			this.data = {
				type: '',
				amount: '',
				pay_date: '',
				bank_no: '',
				bank_name: '',
				cheque_no: '',
				account_no: '',
				account_name: '',
				note: '',
				detail: '',
			}
			this.errorMessage = ''
			this.$validator.reset()
		},
		clearAll() {
			this.item = []
			this.clearData()
		},
	},
}
</script>

<style></style>
