export default [
  {
    path: 'LogDatas/TollLogDataLogs',
    name: `log-toll`,
    component: () => import('@app/LogsDatas/LogTolls'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },

  },
  {
    path: 'LogDatas/CreditDataLogs',
    name: `log-credit`,
    component: () => import('@app/LogsDatas/LogCredit'),
    meta: {
      resource: 'ACL',
      action: 'client',
    },
  },
  {
    path: 'LogDatas/thSMS',
    name: `log-thSMS`,
    component: () => import('@app/LogsDatas/LogThSMS'),

  },



]
