import Vue from 'vue'
import {
  ToastPlugin,
  ModalPlugin,
  BootstrapVue,
  IconsPlugin,
} from 'bootstrap-vue'

window.moment = require('moment')
window.moment.locale('ru')
import DataTable from 'laravel-vue-datatable'

import VueCompositionAPI from '@vue/composition-api'
import VeeValidate, { Validator } from 'vee-validate'
import th from './modules/vee-validate/th'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import alert from './alert'
import api from './api'
import Convert from './modules/Convert'

import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueHead from 'vue-head'
import VueShow from 'vue-show'
Vue.use(VueShow)

Vue.use(VueHead)
// name is optional
Vue.use(VueLodash, { name: 'custom', lodash: lodash })

Vue.use(api)
Vue.use(alert)
Vue.use(Convert)

import VueMeta from 'vue-meta'

Vue.use(VueMeta)

// Global Components
import './global-components'

// 3rd party plugins
// import './load-widgets'
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
import './load-widgets'
import './modules/GobalMixin'

// Axios Mock Adapter
// import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VeeValidate)
Validator.localize('th', th)

Vue.use(DataTable)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
