import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
const self = Vue

const text_array = [{}]
const toast = (title, text, variant, icon, clear = true) => {
  if (clear) self.$toast.clear()
  self.$toast({
    component: ToastificationContent,
    position: 'top-right',
    props: {
      title,
      icon,
      variant,
      text,
      enableHtml: true
    },
  })
}


// self.$toast("I'm a toast!", {
//   position: "top-right",
//   timeout: 5000,
//   closeOnClick: true,
//   pauseOnFocusLoss: true,
//   pauseOnHover: true,
//   draggable: true,
//   draggablePercent: 0.6,
//   showCloseButtonOnHover: false,
//   hideProgressBar: true,
//   closeButton: "button",
//   icon: true,
//   rtl: false
// })



export default function (Vue) {
  Vue.alert = {
    /**
     * จัดการ Response ที่เป็นการเชื่อมต่อกับ Server
     * @param {Object} data ข้อมูลตอบกลับจาก server
     * @param {String} text ข้อความที่ต้องการแสดง
     */
    respCreateOrUpdate(data, text) {
      if (data.data.errors) {
        toast('Response Error', text, 'danger', 'outline-danger')
      } else {
        toast('Response Success', text, 'success', 'outline-danger')
      }
    },

    success(obj) {
      const title = obj.title ?? 'Success'
      const text = obj.text
      toast(title, text, 'success', 'outline-danger')
    },

    validator(obj = { title: null, text: null }) {
      const title = obj.title ?? 'Form Validate'
      const text = obj.text ?? ''
      toast(title, text, 'danger', 'outline-danger')
    },
    errorCode422(obj) {
      try {
        let text = ""
        Object.keys(obj.errors).forEach(async (row) => {
          try {
            // text += obj.errors[row][0] + "</br>"
            await new Promise(resolve => {
              toast('Validator', obj.errors[row][0], 'danger', 'outline-danger', false)
              return setTimeout(resolve, 1000)
            })
          } catch (error) {
            // console.log(error)

          }
        })
      } catch (error) {

      }
    },
    clear() {
      self.$toast.clear()
    },
    async confirmDelete(obj) {
      try {
        const customText = {
          title: 'Confirm Delete', text: 'You want to delete this information.',
          btnConfirmText: 'Confirm',
          btnCancelText: 'Cancel',
          ...obj
        }

        const _action = await self.swal({
          title: customText.title,
          icon: 'warning',
          html: customText.text,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: customText.btnConfirmText,
          cancelButtonText: customText.btnCancelText,
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonAriaLabel: 'Thumbs down',
          customClass: {
            confirmButton: 'btn btn-outline-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        if (_action.value) {
          return true
        }
        return false

      } catch (error) {
        return error
      }
    },
    async confirm(obj) {
      try {
        const customText = {
          title: 'confirm data deletion',
          text: '',
          btnConfirmText: 'Confirm',
          btnCancelText: 'Cancel',
          ...obj
        }

        const _action = await self.swal({
          title: customText.title,
          icon: 'warning',
          html: customText.text,
          showCloseButton: true,
          showCancelButton: true,
          focusConfirm: false,
          confirmButtonText: customText.btnConfirmText,
          cancelButtonText: customText.btnCancelText,
          confirmButtonAriaLabel: 'Thumbs up, great!',
          cancelButtonAriaLabel: 'Thumbs down',
          customClass: {
            confirmButton: 'btn btn-outline-success',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        if (_action.value) {
          return true
        }
        return false

      } catch (error) {
        return error
      }
    }
  }

  Object.defineProperties(Vue.prototype, {
    alert: {
      get() {
        return Vue.alert
      },
    },
  })
}
