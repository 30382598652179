import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import {
  isUserLoggedIn,
  getUserData,
  getHomeRouteForLoggedInUser,
} from '@/auth/utils'
import pages from './routes/pages'

Vue.use(VueRouter)

import routeImporter from '../modules/importers/routeImporter'
const applications = routeImporter(
  require.context('./routes/applications', false, /.*\.js$/)
).map(data => {
  /**
   * เติ่ม application นำหน้า path
   */
  data.path = `/application/${data.path}`
  return data
})
const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...applications,
    ...pages,
    {
      path: '/testx',
      name: 'testx',
      component: () => import('@/views/Test/index.vue'),
    },
    {
      path: '/emailVerify/:user_id',
      component: () => import('@/views/emailVerify/index.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: false,
      }
    },
    {
      path: '/emailVerifyNoti',
      component: () => import('@/views/emailVerify/emailVerifyNoti.vue'),
      meta: {
        layout: 'full',
        resource: 'ACL',
        action: 'client',
      }
    },

    {
      path: '/',
      name: 'welcome',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/login',
      name: 'auth-login',
      component: () => import('@/views/pages/authentication/Login.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/register',
      name: 'auth-register',
      component: () => import('@app/Register/index.vue'),
      meta: {
        layout: 'full',
        resource: 'Auth',
        redirectIfLoggedIn: true,
      },
    },
    {
      path: '/pages/miscellaneous/not-authorized',
      name: 'misc-not-authorized',
      component: () => import('@/views/pages/miscellaneous/NotAuthorized.vue'),
      meta: {
        resource: 'ACL',
        action: 'client',
      }
    },


    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()

  if (!canNavigate(to)) {

    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

export default router
