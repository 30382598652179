<template>
  <b-row>
    <b-col :md="fixed ? fixed : null" v-for="(form, i) in forms" :key="i">
      <b-form-group
        :label="form.label"
        :label-for="form.id"
        :invalid-feedback="errors.first(form.id)"
      >
        <b-form-select
          :id="form.id"
          :name="form.id"
          v-model="form.value"
          @change="getSubItems($event, i)"
          :options="form.options"
          :data-vv-as="form.label"
          v-validate="'required'"
          :state="getFormState(inputFields, form.id, errors)"
        >
        </b-form-select>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      required: true
    },
    value: {
      default: ''
    },
    fixed: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      forms: [],
      inputVal: this.value
    }
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.inputVal = val
    }
  },
  mounted() {
    this.get(this.url).then(({ data }) => {
      this.createOptions(data)
      if (this.value !== '') {
        this.searchItem(data)
      }
    })
  },
  methods: {
    createOptions(data) {
      this.forms.push({
        label: 'หมวดหมู่',
        id: 'catalog',
        options: data,
        value: ''
      })
    },
    getSubItems(value, i) {
      if (
        this.findOptions(value, i) &&
        this.findOptions(value, i).items &&
        this.findOptions(value, i).items.length > 0
      ) {
        if (this.forms.length > i + 1) {
          this.forms.splice(i + 1, 1)
        }
        this.forms[i + 1] = {
          label: '&nbsp;',
          id: `catalog_items_${i}`,
          options: this.findOptions(value, i).items,
          selected: ''
        }
      } else if (this.findOptions(value, i)) {
        this.inputVal = this.findOptions(value, i).id
        this.$emit('change', this.findOptions(value, i))
      }
    },
    findOptions(value, i) {
      return this.forms[i].options.find(item => item.value === value)
    },
    searchItem(data, i = 0) {
      return data.find(item => {
        if (item.items && item.items.length > 0) {
          if (this.searchItem(item.items, i + 1)) {
            this.forms[i] = {
              label: i === 0 ? 'หมวดหมู่' : '&nbsp;',
              id: i === 0 ? 'catalog' : `catalog_items_${i}`,
              options: data,
              value: item.value
            }
            return true
          }
        } else if (item.id === this.value) {
          this.forms[i] = {
            label: '&nbsp;',
            id: `catalog_items_${i}`,
            options: data,
            value: item.value
          }
          return true
        }
        return false
      })
    }
  }
}
</script>

<style></style>
