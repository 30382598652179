<template>
  <div :class="state === null ? '' : state ? 'is-valid' : 'is-invalid'">
    <b-form-select
      ref="select2"
      v-model="inputVal"
      :state="state"
      :options="options"
      v-show="visible"
      :disabled="disabled"
    ></b-form-select>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    value: null,
    state: null,
    options: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      visible: true,
      inputVal: this.value
    }
  },
  watch: {
    value(val) {
      this.inputVal = val
    },
    inputVal(val) {
      this.$emit('input', val)
    },
    options(val) {
      if (val.length > 0) {
        this.init()
      }
    }
  },
  mounted() {
    if (this.options.length > 0) {
      this.init()
    }
  },
  methods: {
    init() {
      const self = this
      $(() => {
        $(self.$el)
          .find('select')
          .select2()
        $(self.$el)
          .find('select')
          .on('select2:select', function(e) {
            const data = e.params.data
            self.inputVal = data.id
            self.visible = false
          })
      })
    }
  }
}
</script>

<style></style>
