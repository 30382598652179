<script>
import { mapState } from 'vuex'

export default {
  render(createElement) {
    var self = this
    return createElement(
      'b-tabs',
      {
        props: {
          pills: self.pills,
          value: self.inputVal
        },
        on: {
          input: function(val) {
            self.inputVal = val
          }
        }
      },
      this.$slots.default.filter(node => {
        if (!node.tag) return false
        if (node.data.attrs) {
          return this.isAllow(node.data.attrs)
        }
        return true
      })
    )
  },
  props: ['name', 'value', 'pills'],
  data() {
    return {
      inputVal: this.value,
      isInit: false
    }
  },
  computed: {
    ...mapState(['routes']),
    slots() {
      return this.$slots.default.filter(node => {
        if (!node.tag) return false
        if (node.data.attrs) {
          return this.isAllow(node.data.attrs)
        } else {
          return true
        }
      })
    }
  },
  watch: {
    value(val) {
      this.inputVal = val
    },
    inputVal(val) {
      const title = this.slots[val].componentOptions.propsData.title
      this.$emit('input', val)
      this.$emit('titleChange', title)
      if (this.isInit) {
        localStorage.setItem(this.name, title)
      } else {
        this.isInit = true
        const tabTitle = localStorage.getItem(this.name)
        if (tabTitle) {
          this.inputVal = this.slots.findIndex(
            item => item.componentOptions.propsData.title === tabTitle
          )
        }
      }
    }
  },
  methods: {
    isAllow(data) {
      if (!data.name) return true
      return Object.keys(this.routes).find(key => key === data.name)
    }
  }
}
</script>
