<template>
  <b-modal
    :title="title"
    ref="modal"
    no-close-on-backdrop
    :size="size ? size : 'lg'"
    @hide="hide"
  >
    <slot />
    <div slot="modal-footer">
      <b-button variant="primary" @click="save" class="mr-1">{{
        okTitle ? okTitle : 'บันทึก'
      }}</b-button>
      <b-button variant="secondary" @click="cancel" class="mr-1"
        >ยกเลิก</b-button
      >
    </div>
  </b-modal>
</template>

<script>
export default {
  props: ['okTitle', 'title', 'size'],
  methods: {
    save(e) {
      this.$emit('save', e)
    },
    cancel(e) {
      this.$emit('cancel', e)
      this.hide()
    },
    show() {
      this.$emit('show')
      this.$refs.modal.show()
    },
    hide() {
      this.$emit('close')
      this.$refs.modal.hide()
    }
  }
}
</script>

<style></style>
