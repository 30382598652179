<template>
    <section>
        <form id="permission-form" ref="permissionForm" @submit="submit">
            <div class="form-group row">
                <label for="inputPermissionName" class="col-md-2 col-form-label">ชื่อสิทธิ์</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" name="name_th" id="inputPermissionName" required />
                </div>
            </div>
            <div class="form-group row">
                <label for="inputPermissionName" class="col-md-2 col-form-label">ชื่อสิทธิ์ อังกฤษ</label>
                <div class="col-md-10">
                    <input type="text" class="form-control" name="name_en" id="inputPermissionName" required />
                </div>
            </div>
            <div class="tree-box">
                <ul class="tree">
                    <li v-for="(menu, i) in data.menus" :key="i">
                        <div>
                            <i class="fas fa-folder" v-if="isNotEmpty(menu.submenu)"></i>
                            <i class="far fa-file" v-if="!isNotEmpty(menu.submenu)"></i>
                            <span @click="expand">
                                {{ menu.title }}
                                <span
                                    class="label label-theme m-l-5"
                                    v-if="isNotEmpty(menu.label)"
                                >{{ menu.label }}</span>
                            </span>
                            <select
                                class="form-control form-control-sm form-control-inline"
                                :class="{ 'select-readonly': isNotEmpty(menu.submenu) }"
                                :name="`permit[${menu.code}]`"
                                @change="selectParent"
                            >
                                <option value="0">ไม่มีสิทธิ์</option>
                                <option value="1">อ่าน</option>
                                <option value="2">แก้ไข</option>
                                <option value="3">อนุมัติ</option>
                            </select>
                        </div>
                        <ul class="sub-menu" v-if="isNotEmpty(menu.submenu)">
                            <li v-for="(sublv1, j) in menu.submenu" :key="j">
                                <div>
                                    <i class="fas fa-folder" v-if="isNotEmpty(sublv1.submenu)"></i>
                                    <i class="far fa-file" v-if="!isNotEmpty(sublv1.submenu)"></i>
                                    <span>{{ sublv1.title }}</span>
                                    <select
                                        class="form-control form-control-sm form-control-inline"
                                        :class="{ 'select-readonly': isNotEmpty(sublv1.submenu) }"
                                        :name="`permit[${sublv1.code}]`"
                                        @change="selectParent"
                                    >
                                        <option value="0">ไม่มีสิทธิ์</option>
                                        <option value="1">อ่าน</option>
                                        <option value="2">แก้ไข</option>
                                        <option value="3">อนุมัติ</option>
                                    </select>
                                </div>
                                <ul class="sub-menu" v-if="isNotEmpty(sublv1.submenu)">
                                    <li v-for="(sublv2, k) in sublv1.submenu" :key="k">
                                        <div>
                                            <i class="fas fa-folder" v-if="isNotEmpty(sublv2.submenu)"></i>
                                            <i class="far fa-file" v-if="!isNotEmpty(sublv2.submenu)"></i>
                                            <span>{{ sublv2.title }}</span>
                                            <select
                                                class="form-control form-control-sm form-control-inline"
                                                :class="{
                          'select-readonly': isNotEmpty(sublv2.submenu)
                        }"
                                                :name="`permit[${sublv2.code}]`"
                                                @change="selectParent"
                                            >
                                                <option value="0">ไม่มีสิทธิ์</option>
                                                <option value="1">อ่าน</option>
                                                <option value="2">แก้ไข</option>
                                                <option value="3">อนุมัติ</option>
                                            </select>
                                        </div>
                                        <ul class="sub-menu" v-if="isNotEmpty(sublv2.submenu)">
                                            <li v-for="(sublv3, i) in sublv2.submenu" :key="i">
                                                <div>{{ sublv3.title }}</div>
                                            </li>
                                        </ul>
                                        <!-- Submenu lv2 -->
                                    </li>
                                </ul>
                                <!-- Submenu lv1 -->
                            </li>
                        </ul>
                        <!-- Main menu -->
                    </li>
                </ul>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
        </form>
    </section>
</template>

<script>
export default {
	props: ['menus'],
	data() {
		return {
			data: {
				menus: []
			}
		}
	},
	watch: {
		menus: function(newVal, oldVal) {
			// watch it
			this.data.menus = newVal
		}
	},
	mounted() {},
	methods: {
		expand(event) {
			$(event.target)
				.closest('li')
				.find('ul')
				.first()
				.toggle()
		},
		selectParent(event) {
			$(event.target)
				.closest('li')
				.find('select')
				.val($(event.target).val())
		},
		submit(event) {
			event.preventDefault()
			const payload = new FormData(this.$refs.permissionForm)
			const token = localStorage.getItem('access_token')
			this.post('/api/officer/role', payload).then(({ data }) => {
				console.log(data)
				this.alertSuccess('บันทึกข้อมูลสำเร็จ')
			})
		}
	}
}
</script>

<style>
ul.tree {
	list-style: none;
}
ul.tree ul {
	display: none;
}
.form-control-inline {
	width: auto;
	display: inline;
}
.select-readonly {
	background-color: #efefef;
}
.tree li {
	line-height: 45px;
	font-size: 14px;
}
.tree-box {
	background-color: #eee;
	border-radius: 5px;
	margin-top: 16px;
}
</style>
