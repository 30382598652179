//
export default [

  {
    path: `dashboards`,
    name: `dashboards`,
    component: () => import('@app/dashboards'),
    // meta: {
    //   resource: 'ACL',
    //   action: 'client',
    // },
  },
]
