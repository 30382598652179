<template>
  <vSelect
    :name="name"
    :id="id"
    v-model="inputVal"
    :options="options"
    :track-by="trackBy"
    :label="label"
    :placeholder="placeholder"
    :disabled="disabled"
  >
    <div slot="no-options">ไม่พบคำที่ต้องการค้นหา</div>
  </vSelect>
</template>
<script>
import vSelect from 'vue-select'
export default {
  props: [
    'name',
    'id',
    'options',
    'trackBy',
    'label',
    'value',
    'disabled',
    'placeholder'
  ],
  components: { vSelect },
  watch: {
    inputVal(val) {
      if (val) {
        this.$emit('input', val.value)
      } else {
        this.$emit('input', val)
      }
    },
    value(val) {
      this.inputVal = this.options.find(item => item.value === val)
    }
  },
  data() {
    return { inputVal: this.value }
  }
}
</script>
