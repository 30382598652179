
export default [

  {
    path: `ManageHacsIntegration`,
    name: `Manage-Hacs-Integration`,
    component: () => import('@admin/AdminHacsIntegration'),
  },
  {
    path: `ManageCallNodeRed`,
    name: `Manage-CallขNode-Red`,
    component: () => import('@admin/AdminCallNodeRed'),
  },
  {
    path: `ManageCallAutomation`,
    name: `Manage-Call-Automation`,
    component: () => import('@admin/AdminCallAutomation'),
  },
]
