<template>
    <b-row>
        <b-col sm="3">
            <label :for="id">{{ label }}:</label>
        </b-col>
        <b-col sm="9">
            <b-form-input
                :id="id"
                v-model="vModel"
                :type="type"
                :required="required"
                :max="max"
                :min="min"
                :step="step"
                :state="state"
            ></b-form-input>
            <b-form-invalid-feedback v-if="state === false">
                {{
                error
                }}
            </b-form-invalid-feedback>
        </b-col>
    </b-row>
</template>

<script>
export default {
	props: [
		'id',
		'state',
		'error',
		'label',
		'vModel',
		'pladeholder',
		'type',
		'required',
		'max',
		'min',
		'step'
	]
}
</script>

<style></style>
