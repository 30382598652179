<template>
  <panel title=" ช่องทางการรับเงิน" bordered>
    <b-row>
      <form-input label="เลขใบสำคัญ *" :col="3" v-model="form.ref_no" v-if="false" />

      <form-select
        label="ช่องทาง *"
        :options="options"
        :col="6"
        trackLable="text"
        v-model="form.way"
        data-vv-as="ช่องทาง"
        v-validate="'required'"
      />

      <form-input-currency
        :disabled="disabled"
        label="จำนวนเงิน"
        v-model="form.amount"
        data-vv-as="จำนวนเงิน"
        :col="6"
        :checkMaximumInput="checkMaximumInput"
        :maximumInput="maximumInput"
      />
    </b-row>
    <b-row v-show="_type == 'โอน'">
      <b-col sm="12">
        <b-form-group label="เลือกเส้นทาง การโอนเงิน">
          <b-form-radio-group
            id="radio-group-2"
            v-model="radio"
            name="radio-sub-component"
            v-validate="_type == 'โอน' ? `required` : ``"
          >
            <b-form-radio value="inside">ธนาคาร ภายในสหกรณ์</b-form-radio>
            <b-form-radio value="outside">ธนาคาร ภายนอก</b-form-radio>
          </b-form-radio-group>
        </b-form-group>
      </b-col>

      <form-select
        v-show="radio === 'inside'"
        label="เลือก ธนาคาร *"
        v-model="selectAccountBankInside"
        data-vv-as="เลือกธนาคาร"
        :options="bankAccounts"
        :trackLable="`label`"
        :col="12"
      />

      <form-select
        v-show="radio === 'outside'"
        label="เลือก ธนาคาร * "
        :options="_options"
        :col="12"
        trackLable="text"
        v-model="form.bank_name"
        data-vv-as="ธนาคาร"
        v-validate="radio === 'outside' ? 'required' : false"
      />
    </b-row>

    <b-row v-if="_type != 'เงินสด' && _type">
      <form-select
        v-if="_type === 'เช็ค false'"
        label="เลือก ธนาคาร * "
        :options="_options"
        :col="12"
        trackLable="text"
        v-model="form.bank_name"
        data-vv-as="ธนาคาร"
        v-validate="_type != 'เงินสด' && _type ? 'required' : ``"
      />
      <form-input
        label="เลขที่บัญชี *"
        :col="6"
        v-model="form.account_no"
        data-vv-as="เลขที่บัญชี"
        v-validate="_type == 'โอน' ? 'required' : ``"
        v-show="_type === 'โอน'"
      />

      <form-input v-if="_type == 'โอน'" label="สาขา" v-model="form.bank_branch" :col="6" />
    </b-row>
  </panel>
</template>
<script>
export default {
	props: {
		checkMaximumInput: {
			type: Boolean,
			default: false,
		},
		maximumInput: {
			type: Number,
			default: 0,
		},
		oldValue: Object,
	},
	watch: {
		radio() {
			this.form.account_no = ''
			this.form.bank_branch = ''
			this.selectAccountBankInside = ''
			this.form.bank_name = ''
			this.$nextTick(() => {
				this.errors.clear()
				this.$nextTick(() => {
					this.$validator.reset()
				})
			})
		},
		selectAccountBankInside(val) {
			if (!val) {
				return ''
			}
			this.form.account_no = val.account_no
			this.form.bank_branch = val.name
		},
		'form.bank_name'(val) {
			this.form.account_no = '' // val.account_no
		},
		'form.way'(val) {
			if (!val) return ''
			this.clearForm()
		},
		'form.amount'(newVal, oldVal) {
			this.checkMax(newVal, oldVal)
		},
		form: {
			handler() {
				this.handleSubmit('notValidate')
			},
			deep: true,
		},
	},

	data() {
		return {
			disabled: false,
			selectAccountBankInside: '',
			radio: 'inside',
			markIndex: '', // เก็บ index ตัวหลัก ที่จะเพิ่มหรือแก้ไข

			form: {
				way: '',
				bank_name: '',
				account_no: '-', // เลขบัญชี
				bank_branch: '', // สาขา
				Attached: '',
				amount: this.oldValue ? this.oldValue.amount : '',
				transfer_type: '', // เก็บกรณีเป็นโอนเงิน เก็บว่าเป็นการโอรภายในหรือภายนอก
			},
			options: [
				{ value: '21100', text: 'เงินสด' },
				{ value: '22110', text: 'เช็ค' },
				{ value: '23111', text: 'โอน' },
			],
			optBank: '', // เก็บธนาคารของเช็ค
			bankTranfers: [
				{
					text: 'ธนาคาร กรุงเทพ จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร กรุงไทย จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร กรุงศรีอยุธยา จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร กสิกรไทย จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร เกียรตินาคิน จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ซีไอเอ็มบีไทย จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ทหารไทย จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ทิสโก้จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ไทยพาณิชย์จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ธนชาต จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ยูโอบีจำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร แลนด์แอนด์เฮ้าส์จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร สแตนดาร์ดชาร์เตอร์ด (ไทย) จำกัด (มหาชน)',
					value: '901',
				},
				{
					text: 'ธนาคาร ไอซีบีซี(ไทย) จำกัด (มหาชน) ',
					value: '901',
				},
			],
			/**
			 * เก็บบัญชีที่เปิดไว้กับสหกรณืเอง
			 */
			bankAccounts: [],
		}
	},
	computed: {
		_type() {
			return this.form.way ? this.form.way.text : ''
		},
		_options() {
			return this._type === 'เช็ค' ? this.optBank : this.bankTranfers
		},
	},
	async created() {
		try {
			this.getFromList()
			this.queryBankAccountOfMember()
			console.log(this.memberData)
		} catch (error) {
			console.error(error)
			return ''
		}
	},
	methods: {
		async queryBankAccountOfMember(memberOrHrID = '') {
			try {
				this.bankAccounts = await this.api.post(
					`/api/officer/account/deposit/search/query`,
					{
						prefix: this.system.prefix,
						guarantees: [],
						member_data_id: memberOrHrID
							? memberOrHrID
							: this[this.system.kind].id,
					}
				)
			} catch (error) {
				let { member_data_id, hr_data_id } = this.oldValue
				const memberOrHrID = member_data_id ? member_data_id : hr_data_id

				this.queryBankAccountOfMember(memberOrHrID)
				return ''
			}
		},
		async getFromList() {
			try {
				let { data } = await this.get(`/api/payment/getFromList`)
				this.optBank = data
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		checkMax(newValue, oldValue) {
			if (this.checkMaximumInput) {
				if (newValue * 1 > this.maximumInput * 1) {
					this.form.amount = this.maximumInput * 1
					// console.log(this.form.amount, newValue, this.maximumInput)
				}
			}
		},

		async handleSubmit(checkValidate = '') {
			try {
				const validatetor = await this.$validator.validateAll()
				if (!validatetor && checkValidate == '') {
					this.alertValidate()
					return validatetor
				}

				if (this._type === 'โอน') {
					this.form.transfer_type = this.radio
				} else {
					this.form.transfer_type = ''
				}
				this.$emit('callback', { form: this.form, index: this.markIndex })
				return true
			} catch (error) {
				console.error(error)
				return ''
			}
		},

		clearForm() {
			const form = this.form
			Object.keys(form).forEach((f) => {
				if (f === 'amount') {
					this.form[f] = this.oldValue.amount ? this.oldValue.amount : ''
				} else if (f != 'way') {
					this.form[f] = ''
				}
			})
		},
		async getPaymentData() {
			try {
				const validatetor = await this.$validator.validateAll()
				if (!validatetor) return false
				let payment = Object.assign({}, this.form)
				payment.way = JSON.stringify(payment.way)
				payment.bank_name = JSON.stringify(payment.bank_name)
				if (this._type != 'เงินสด' && this._type) {
					this.$set(payment, 'bank_type', this.radio)
				}
				return payment
			} catch (error) {
				console.log(error)
				return false
			}
		},
	},
	mounted() {
		try {
			if (!this.oldValue.length) return ''
			const {
				type,
				ways_code,
				amount,
				transfer_type,
				bank_name,
				bank_code,
				account_no,
				bank_branch,
			} = this.oldValue
			this.form.way = { text: type, value: ways_code }

			setTimeout(() => {
				this.form.amount = amount
				this.radio = transfer_type
				this.disabled = true

				setTimeout(() => {
					this.form.bank_name = {
						text: bank_name,
						value: bank_code,
					}
				}, 100)
				setTimeout(() => {
					this.form.account_no = account_no
					this.form.bank_branch = bank_branch
				}, 300)
			}, 1000)
		} catch (error) {
			this.disabled = false
			return ''
		}
	},
}
</script>
