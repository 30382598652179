<template>
  <section>
    <b-row>
      <b-col>
        <ul class="attached-document clearfix">
          <li class="" v-for="(row, index) in datas" :key="index">
            <div class="document-file">
              <a href="javascript:;" v-if="row.file_mime.startsWith('image')">
                <img
                  :src="row.link_url"
                  class="img-fluid"
                  @click="viewFile(row)"
                />
              </a>
              <a href="javascript:;" v-else>
                <i class="fa fa-file-pdf" @click="viewFile(row)"></i>
              </a>
            </div>
            <div class="document-name">
              <a href="javascript:;">{{ row.file_name }}</a>
              <span class="float-right">
                <i class="fa fa-info-circle" @click="infoFile(index)"></i>
                <i
                  v-if="isReadOnly == false"
                  class="fa fa-trash"
                  @click="deleteFile(index)"
                ></i>
              </span>
            </div>
          </li>

          <!-- <li v-if="isReadOnly == false">
            <div class="document-file" @click="selectFile">
              <a href="javascript:;">
                <i class="fas fa-plus"></i>
              </a>
            </div>
          </li> -->
        </ul>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  props: {
    dataID: {
      type: String,
      required: false
    },
    title: {
      type: String,
      default: 'ไฟล์ที่เกี่ยวข้อง'
    },
    isMain: {
      type: Boolean,
      required: false
    },
    isExpand: {
      type: Boolean,
      required: false
    },
    isReadOnly: {
      type: Boolean,
      required: false
    }
  },
  data: () => ({
    datas: [],
    data: {
      hr_data_id: '',
      hr_attachedfile_id: '',
      file: '',
      hr_attachedfile_item_id: '',
      file_name: '',
      file_path: '',
      file_mime: '',
      link_url: '',
      date: '',
      menu_order: ''
    },
    fileAttach: '',
    attachedfile_id: ''
  }),
  mounted() {
    this.datas = []
    this.fileAttach = ''
    if (this.dataID) {
      this.get(`/api/attachedfile/${this.dataID}/${this.isMain}`).then(
        ({ data }) => {
          if (data) {
            this.datas = data
          }
        }
      )
    }
  },
  destroyed() {
    this.datas = []
    this.fileAttach = ''
  },
  watch: {
    dataID: {
      handler: function(value) {
        if (value) {
          this.get(`/api/attachedfile/${this.dataID}/${this.isMain}`).then(
            ({ data }) => {
              this.datas = data
            }
          )
        }
      }
    },
    fileAttach: {
      handler: function(value) {
        if (value) {
          let temp = {
            attachedfile_id: this.attachedfile_id,
            file: value,
            attachedfile_item_id: '',
            file_name: value.name,
            file_path: '',
            file_mime: value.type,
            link_url: URL.createObjectURL(value),
            date: '',
            menu_order: ''
          }
          this.datas.push(temp)
          this.$emit('change', this.datas)
        }
      }
    }
  },
  methods: {
    submit() {
      if (this.datas.length == 0 && this.AttachedID) {
        return this.del(`/api/hrattachedfile/${this.AttachedID}`)
      } else {
        return this.post(`/api/hrattachedfile`, this.datas)
      }
    },
    handleFile(e) {
      this.fileAttach = ''
      this.fileAttach = e.target.files[0]
    },
    selectFile() {
      this.$refs.fileAttach.click()
    },
    infoFile(id) {
      console.log(this.datas[id])
    },
    deleteFile(id) {
      console.log(this.datas[id])
      this.datas.splice(id, 1)
    },
    clearData() {
      this.datas = []
      this.fileAttach = ''
      this.$refs.fileAttach.value = ''
    },
    viewFile(data) {
      if (data.file_path) {
        $.fancybox.open({
          src: data.link_url,
          type: data.file_mime.includes('image') ? 'image' : 'iframe'
        })
      } else {
        $.fancybox.open({
          src: data.link_url,
          type: data.file_mime.includes('image') ? 'image' : 'iframe'
        })
      }
    }
  }
}
</script>

<style>
.attached-document > li {
  width: 164px !important;
}
</style>
