<template>
	<div>
		<label for="example-i18n-picker" v-html="label" />

		<b-form-datepicker
			id="example-i18n-picker"
			v-model="vModel"
			:locale="'th'"
			:placeholder="pl"
			class="mb-2"
			:date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'long' }"
		/>
	</div>
</template>

<script>
export default {
	props: {
		label: {
			type: String,
			default: '',
		},
		pl: {
			type: String,
			default: 'เลือก วันที่',
		},
		id: String,
		name: String,
		value: null,
		state: null,
		invalidFeedback: String,
		format: {
			type: String,
			default: 'DD/MM/YYYY',
		},
		startRef: null,
		endRef: null,
		lockDate: null,
		/**
		 * ถ้าต้องการใช้วันปัจจุบัน ให้กรอก true เข้ามา
		 */
		now: {
			type: Boolean,
			default: false,
		},
		/**
		 * ----------------------------
		 * @param minDate
		 * @default false
		 * ----------------------------
		 * @true ไม่สามารถเลือกวันย้อนหลังได้
		 * @false สามารถเลือกวันย้อนหลังได้
		 */
		minDate: {
			type: Boolean,
			default: false,
		},
		/**
		 * ----------------------------
		 * @param MaxDate
		 * @default false
		 * ----------------------------
		 * @true ไม่สามารถเลือกวันล่วงหน้าได้
		 * @false สามารถเลือกวันล่วงหน้าได้
		 */
		maxDate: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (!val) return ''
				this.vModel = val
			},
		},
	},
	data() {
		return {
			vModel: '',
			showDecadeNav: false,
			hideHeader: false,
		}
	},
}
</script>