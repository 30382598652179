const meta = {
  meta: {
    resource: 'ACL',
    action: 'client',
  }
}
export default [

  {
    path: `user`,
    name: `users`,
    component: () => import('@app/Users'),
  },
  {
    path: `user-view/:user_id`,
    name: `users-view`,
    component: () => import('@app/Users/view.vue'),
  },
  {
    path: `user-profile`,
    name: `pages-profile`,
    component: () => import('@app/UserProfile/index.vue'),
    ...meta
  },
  {
    path: `user-profile/:user_id`,
    name: `users-profile-info`,
    component: () => import('@app/Users/view.vue'),
    ...meta
  },
]
