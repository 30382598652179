<template>
	<b-col :sm="col">
		<div class="form-group">
			<label for="">{{ label }}</label>
			<b-row>
				<b-col md="6">
					<DatePicker
						ref="start"
						v-model="form.start"
						@input="handelCallBack"
						:name="name"
						:now="true"
					/>
				</b-col>
				<b-col md="6">
					<DatePicker
						ref="end"
						v-model="form.end"
						@input="handelCallBack"
						:name="name"
						:now="true"
					/>
				</b-col>

				<b-col sm="12">
					<small
						id="helpId"
						class="form-text text-danger"
						v-if="errors.items.find(f => f.field === name)"
					>{{ errors.items.find(f => f.field === name).msg }}</small>
				</b-col>
			</b-row>
		</div>
	</b-col>
</template>

<script>
export default {
	props: {
		/**
		 * form : {
		 *  start: '',
		 *  end: ''
		 * }
		 */
		value: null,
		col: {
			type: Number,
			default: 12
		},
		label: {
			type: String,
			default: ''
		},
		pl: {
			type: String,
			default: ''
		}
	},
	data() {
		return {
			name: 'name' + Math.floor(Math.random() * (0 - 99 + 999)) + 1,
			placeholder: this.pl ? this.pl : `เลือก ${this.label}`,
			inputVal: '',
			form: {
				start: '',
				end: ''
			}
		}
	},
	watch: {
		value(val) {
			this.form = val
		}
	},
	methods: {
		handelCallBack() {
			this.$emit('input', this.form)
		}
	}
}
</script>

<style></style>
