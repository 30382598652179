<template>
  <b-modal
    id="notification-modal"
    ref="notification-modal"
    hide-footer
    title="การแจ้งเตือน"
    no-close-on-backdrop
  >
    <div>
      <b-alert
        v-for="(notification, i) in _notifications"
        :variant="notification.variant"
        :key="`alert-notification-${i}`"
        show
      >
        <h5 class="alert-heading">{{ notification.title }}</h5>
        <span>{{ notification.message }}</span>
      </b-alert>
    </div>
  </b-modal>
</template>
<script>
export default {
  mounted() {
    // console.log(this.notifications)
  },
  props: {
    notifications: {
      required: true,
      default: () => []
    }
  },
  computed: {
    _notifications() {
      return this.notifications
    }
  }
}
</script>

<style></style>
