
import Vue from 'vue'
const self = Vue

export const state = {
  creditBalance: 0
}

export const mutations = {
  UPDATE_CREDIT_BALANCE(state, payload) {
    state.creditBalance = payload
  }
}

export const actions = {
  async ac_fetchCredit({ commit }) {
    try {
      const resp = await self.api.get(`/api/getCreditBalance`)
      commit('UPDATE_CREDIT_BALANCE', resp?.amount ?? 0)
    } catch (error) { console.error(error); return "" }
  }
}

export const getters = {
  _creditBalance(state) {
    return state.creditBalance
  }
}