<template>
  <b-button
    variant="outline-primary"
    class="btn-icon"
    @click="clickMe"
    :title="text"
    text="Submit All"
  >
    {{ text }}
  </b-button>
</template>

<script>
import Ripple from "vue-ripple-directive"
export default {
  directives: {
    Ripple,
  },
  props: {
    text: {
      type: String,
      default: "Submit All",
    },
    size: {
      type: String,
      default: "sm",
    },
  },
  methods: {
    clickMe() {
      this.$emit("clickMe")
    },
  },
}
</script>
