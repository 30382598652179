<template>
  <b-modal
    :title="title"
    ref="modal"
    no-close-on-backdrop
    :size="size || 'lg'"
    @hide="hide"
  >
    <div slot="modal-footer">
      <b-button variant="secondary" @click="cancel" class="mr-1">
        ยกเลิก
      </b-button>
      <b-button variant="warning" @click="save" class="mr-1">
        <span v-if="!loading">บันทึก</span> <span v-else>Loading</span>
      </b-button>
      <b-button v-if="requestable" variant="info" @click="request"
        >ส่งคำขอ</b-button
      >
    </div>
    <slot />
  </b-modal>
</template>

<script>
export default {
  // props: ['okTitle', 'title', 'loading', 'size'],
  props: {
    okTitle: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: false
    },
    loading: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'lg'
    },
    requestable: {
      type: Boolean,
      default: true
    }
  },
  watch: {
    loading() {
      this.setupLoad()
    }
  },
  methods: {
    request(e) {
      this.$emit('request', e)
    },
    save(e) {
      this.$emit('save', e)
    },
    cancel(e) {
      this.$emit('cancel', e)
      this.hide()
    },
    show() {
      this.$emit('show')
      this.$refs.modal.show()
    },
    hide() {
      this.$emit('close')
      this.$refs.modal.hide()
    },
    setupLoad() {
      var target = $('.modal-content')
      if (this.loading) {
        var targetBody = $(target).find('.modal-body')
        var spinnerHtml =
          '<div class="modal-loader"><span class="spinner-small"></span></div>'
        $(target).addClass('modal-loading')
        $(targetBody).prepend(spinnerHtml)
      } else {
        $(target).removeClass('modal-loading')
        $(target)
          .find('.modal-loader')
          .remove()
      }
    }
  }
}
</script>

<style></style>
