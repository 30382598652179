<template>
	<section>
		<b-row>
			<form-select
				label="ประเภทห้องพัก"
				v-model="vModel"
				v-validate="`required`"
				data-vv-as="ประเภทห้องพัก"
				:options="options"
				trackLable="room_type"
				:col="col"
			/>
		</b-row>
		<b-row v-if="vModel">
			<form-input-currency
				label="ค่าเช่าราย วัน"
				v-model="vModel.calculate_days_rent"
				v-validate="`required`"
				data-vv-as="ค่าเช่าราย วัน"
				:col="6"
			/>
			<form-input-currency
				label="ค่าเช่าราย เดือน"
				v-model="vModel.calculate_monthly_rent"
				v-validate="`required`"
				data-vv-as="ค่าเช่าราย เดือน"
				:col="6"
			/>
			<form-input-currency
				label="ค่าประกันห้องพัก ราย วัน"
				v-model="vModel.room_insurance_day"
				v-validate="`required`"
				data-vv-as="ค่าประกันห้องพัก ราย วัน"
				:col="6"
			/>
			<form-input-currency
				label="ค่าประกันห้องพัก ราย เดือน"
				v-model="vModel.room_insurance_month"
				v-validate="`required`"
				data-vv-as="ค่าประกันห้องพัก ราย เดือน"
				:col="6"
			/>
		</b-row>
	</section>
</template>
<script>
export default {
	props: {
		room_type_data_id: String,
		value: null,
		col: {
			type: Number,
			default: 6,
		},
	},
	watch: {
		value: {
			handler: function(val) {
				if (!val) {
					return ''
				}
				this.vModel = val
			},
			immediate: true,
		},
	},
	data() {
		return {
			vModel: '',
			options: [],
			form: {
				id: '', //"": "A354CE6B-B6FA-4237-AF32-B3213A18E41B",
				control_data_id: '', //"": "57E21CB7-57C7-4A7D-B3F3-F32B53ED55EA",
				room_type: '', //"": "ห้องพัดล้ม",
				calculate_monthly_rent: '', //"": "1500.00",
				calculate_days_rent: '', //"": "300.00",
				room_insurance_month: '', //"": "3500.00",
				room_insurance_day: '', //"": "100.00",
				menu_order: '', //"": 1,
				created_at: '', //"": "2021-05-19T13:05:25.000000Z",
				updated_at: '', //"": "2021-05-19T13:05:25.000000Z",
				created_by: '', //"": "CD7F98CB-A40E-4946-9B71-0C6EB2871E6A",
				updated_by: '', //"": "CD7F98CB-A40E-4946-9B71-0C6EB2871E6A"
			},
		}
	},
	mounted() {
		this.fetchRoomTypeData()
	},
	methods: {
		async fetchRoomTypeData() {
			try {
				const resp = await this.api.get(
					`/api/setting/roomTypesSettings/fetchSelect`
				)
				this.options = resp
			} catch (error) {
				console.error(error)
				return ''
			}
		},
		async handleSubmit() {
			try {
				const validatetor = await this.$validator.validateAll()
				if (!validatetor) {
					this.alert.validator()
					return false
				}
				this.$emit('input', this.vModel)
				return this.vModel
			} catch (error) {
				console.error(error)
				return ''
			}
		},
	},
}
</script>
