const masterData = () => {
  return {
    form: {
      id: "",
      name: "",
      detail: "",
      amount: 0,
      price: 0,
      image_datas: [],
      package_list_datas: []
    },
    form_package_list: {
      id: "",
      package_data_id: "",
      name: "",
      detail: "",
      rule_name: "",
      rule_value: "",
      rule_json: "",

    }
  }
}

export const state = masterData()
export const mutations = {
  PUT_PACKAGE_LIST_DATAS(state, payload) {
    const _payload = payload
    _payload._id = parseInt(Math.random() * 1000000000123456567, 10)
    if (typeof state.form.package_list_datas === 'string') {
      state.form.package_list_datas = [_payload]

    } else {
      state.form.package_list_datas.push(_payload)
    }
  },
  EDIT_PACKAGE_LIST_DATAS(state, payload) {
    const findIndex = state.form.package_list_datas.findIndex(packages => packages._id === payload.form._id)
    state.form.package_list_datas[findIndex] = payload.form
    state.form.package_list_datas.push([])
    state.form.package_list_datas.splice(
      state.form.package_list_datas.length - 1, 0
    )
  }

}
export const getters = {
  _columns_package_form_list() {
    return [
      {
        label: "ชื่อ",
        field: "name",
        thClass: "text-center",
        type: "string",
      },
      {
        label: "รายละเอียด",
        field: "detail",
        // tdClass: "text-right",
        thClass: "text-center",
        type: "string",
      },
      {
        label: "value",
        field: "value",
        tdClass: "text-right",
        thClass: "text-center",
        type: "number",
      },

    ]
  },
  _get_package_list_datas() {
    return state.form.package_list_datas
  }
}