<template>
  <div>
    <button
      type="button"
      class="btn btn-primary btn-xs"
      v-if="viewable"
    >
      <i class="fa fa-search"></i>
    </button>
    <button
      type="button"
      class="btn btn-warning btn-xs"
      v-if="editable"
    >
      <i class="fa fa-edit"></i>
    </button>
    <button
      type="button"
      class="btn btn-danger btn-xs"
      v-if="deleteable"
    >
      <i class="fa fa-trash-alt"></i>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    viewable: {
      type: Boolean,
      default: true
    },
    editable: {
      type: Boolean,
      default: true
    },
    deleteable: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style>
</style>
