<template>
  <section>
    <div class="vertical-box">
      <div
        class="vertical-box-column p-r-30 d-none d-lg-table-cell"
        style="width: 215px"
      >
        <div id="external-events" class="fc-event-list">
          <div v-for="(preset, i) in presets" :key="i">
            <h5 class="m-t-0 m-b-15" v-text="preset.name" />

            <div
              class="fc-event"
              :data-color="item.color"
              :data="JSON.stringify(item)"
              v-for="(item, j) in preset.items"
              :key="j"
            >
              <div class="fc-event-icon">
                <i
                  class="fas fa-circle fa-fw f-s-9"
                  :style="`color: ${item.color ? item.color : 'black'}`"
                ></i>
              </div>
              {{ item.name }}
            </div>
            <hr class="bg-grey-lighter m-b-15" />
          </div>
        </div>
      </div>
      <div :id="id" class="vertical-box-column calendar"></div>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex"
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    presets: {
      type: Array,
      default: () => [],
    },
    url: {
      type: String,
      required: true,
    },
  },
  computed: mapState(["user"]),
  methods: {
    init() {
      const self = this
      $(function () {
        $("#external-events .fc-event").each(function () {
          $(this).data("event", {
            title: $.trim($(this).text()), // use the element's text as the event title
            stick: false, // maintain when user navigates (see docs on the renderEvent method)
            color: $(this).attr("data-color") ? $(this).attr("data-color") : "",
          })
          $(this).draggable({
            zIndex: 999,
            revert: true, // will cause the event to go back to its
            revertDuration: 0, //  original position after the drag
          })
        })

        $(`#${self.id}`).fullCalendar({
          locale: "th",
          header: {
            // left: 'month,agendaWeek,agendaDay',
            left: "",
            center: "title",
            right: "prev,today,next ",
          },
          droppable: true, // this allows things to be dropped onto the calendar
          eventDrop: function (event) {
            self.update(event)
          },
          drop: function (date) {
            const data = JSON.parse($(this).attr("data"))
            self.$emit("drop", {
              hr_calendar_topic_id: data.id,
              title: data.name,
              color: data.color,
              start: date.format("DD/MM/YYYY").convertDate(),
              end: date.add(1, "days").format("DD/MM/YYYY").convertDate(),
              url: data.url,
            })
          },
          selectable: true,
          selectHelper: true,
          select: function (start, end) {
            self.$emit("select", {
              start: start.format("DD/MM/YYYY").convertDate(),
              end: end.format("DD/MM/YYYY").convertDate(),
            })
          },
          editable: true,
          eventResize: function (event) {
            self.update(event)
          },
          eventClick: function (event) {
            self.edit(event)
          },
          eventLimit: true, // allow "more" link when too many events
          events: {
            url: self.url,
            type: "POST",
            beforeSend: (request) => {
              request.setRequestHeader(
                "Authorization",
                `Bearer ${self.auth.access_token}`
              )
            },
          },
        })
      })
    },
    refresh() {
      $(`#${this.id}`).fullCalendar("refetchEvents")
    },
    update({ id, title, color, start, end, url, hr_data_id }) {
      if (!hr_data_id) return
      this.$emit("update", {
        id,
        title,
        color,
        url,
        start: start.format("DD/MM/YYYY").convertDate(),
        end: end.format("DD/MM/YYYY").convertDate(),
      })
    },
    edit({ id, title, color, start, end, url, hr_data_id }) {
      if (!hr_data_id) return
      this.$emit("clickEdit", {
        id,
        title,
        color,
        url,
        start: start.format("DD/MM/YYYY").convertDate(),
        end: end.format("DD/MM/YYYY").convertDate(),
      })
    },
    cancel() {
      $(`#${this.id}`).fullCalendar("unselect")
    },
  },
}
</script>

<style></style>
