<template>
  <div>
    <!-- <b-row v-for="(option, j) in options" :key="j"> -->
    <b-row :sm="col">
      <div class="form-group" v-for="(option, j) in options" :key="j">
        {{`${id}_${j}`}}
        <div :class="['custom-control custom-switch',variant[j]]">
          <input type="checkbox" class="custom-control-input" :id="`${id}_${j}`" checked />
          <label class="custom-control-label" :for="`${id}_${j}`"></label>
        </div>
      </div>
    </b-row>
    <!-- </b-row> -->
  </div>
</template>

<script>
export default {
	props: {
		value: null,
		col: {
			type: Number,
			default: 12,
		},
		options: Object,
		id: {
			type: Number,
			default: 0,
		},
		variant: {
			type: Array,
			default: ['default'],
		},
	},
	data() {
		return {}
	},
	watch: {},
	methods: {},
}
</script>

<style>
.default .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
}
.warning .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #ff9909;
	border-color: #ff9909;
}
.success .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #00b3ac;
	border-color: #00b3ac;
}
.danger .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #ff2956;
	border-color: #ff2956;
}
.primary .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #7573b7;
	border-color: #7573b7;
}
.info .custom-control-input:checked ~ .custom-control-label::before {
	color: #fff;
	background-color: #0085e3;
	border-color: #0085e3;
}
</style>