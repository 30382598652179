<template>
	<b-col :sm="col">
		<div class="form-group xxxx">
			<label for="example-i18n-picker" v-html="label" />
			<b-form-datepicker
				:id="name"
				v-model="vModel"
				:locale="'th'"
				:placeholder="pl"
				:date-format-options="{
					year: 'numeric',
					month: 'short',
					day: '2-digit',
				}"
				:size="'sm'"
				@input="_input"
				:now="now"
			/>
		</div>
	</b-col>
</template>

<script>
export default {
	props: {
		now: {
			type: Boolean,
			default: false,
		},

		star: Boolean,
		label: {
			type: String,
			default: '',
		},
		pl: {
			type: String,
			default: 'เลือก วันที่',
		},
		col: {
			type: Number,
			default: 12,
		},

		id: String,
		value: null,
		state: null,
		invalidFeedback: String,
		format: {
			type: String,
			default: 'DD/MM/YYYY',
		},
		startRef: null,
		endRef: null,
		now: {
			type: Boolean,
			default: false,
		},
		/**
		 * ----------------------------
		 * @param minDate
		 * @default false
		 * ----------------------------
		 * @true ไม่สามารถเลือกวันย้อนหลังได้
		 * @false สามารถเลือกวันย้อนหลังได้
		 */
		minDate: {
			type: Boolean,
			default: false,
		},
		/**
		 * ----------------------------
		 * @param MaxDate
		 * @default false
		 * ----------------------------
		 * @true ไม่สามารถเลือกวันล่วงหน้าได้
		 * @false สามารถเลือกวันล่วงหน้าได้
		 */
		maxDate: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		readonly: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		value: {
			immediate: true,
			handler(val) {
				if (!val) return ''
				this.vModel = val
			},
		},
		vModel() {
			this._input()
		},
	},
	mounted() {
		if (this.now) {
			this.vModel = moment().format('YYYY-MM-DD')
		}
	},
	data() {
		return {
			name:
				`name_` + Math.floor(Math.random() * (0 - 999999 + 999999999999)) + 1,
			vModel: '',
			showDecadeNav: false,
			hideHeader: false,
		}
	},
	methods: {
		_input() {
			this.$emit('input', this.vModel)
		},
	},
}
</script>

<style scrop>
.xxxx {
	margin-top: -1.8px;
}
.form-control-sm {
	line-height: 1.91 !important;
	/* padding: 1px !important; */
}
/* .label.form-control.text-break.text-wrap.bg-transparent.form-control-sm {
	margin-top: 4px;
} */
</style>
