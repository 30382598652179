<template>
	<form-select
		:label="label"
		v-model="vModel"
		v-validate="`required`"
		:data-vv-as="label"
		:options="options"
		:col="col"
		@input="_return"
	/>
</template>
<script>
export default {
	props: {
		value: '',
		col: {
			type: Number,
			default: 6,
		},
		label: {
			type: String,
			default: '',
		},
	},
	watch: {
		value: {
			handler: function(val) {
				this._return()
			},
			deep: true,
		},
	},
	data() {
		return {
			options: [],
			vModel: '',
			majorInfo: '',
		}
	},
	mounted() {
		this.fetchMajorInfor()
	},
	methods: {
		_return() {
			this.$emit('input', this.vModel)
		},
		async fetchMajorInfor() {
			try {
				const resp = await this.api.get(
					`/api/setting/major/setting/showWhereMajorId`
				)
				/**
				 * แตกจำนวนชั้นออกมา
				 */
				for (let index = 0; index < resp.count_floor; index++) {
					this.options.push(index + 1)
				}
				return ''
			} catch (error) {
				console.error(error)
				return ''
			}
		},

		async validator() {
			try {
			} catch (error) {
				console.error(error)
				return ''
			}
		},
	},
}
</script>
