<template>
  <b-col :sm="col">
    <div class="form-group">
      <label for>
        {{ label }}
        <font color="red" v-if="star">*</font>
      </label>
      <b-form-input
        type="search"
        :id="id"
        :name="name"
        v-model="inputVal"
        @keydown="keydown"
        autocomplete="off"
        :disabled="disabled"
        :readonly="readonly"
      />
      <div class="invalid-feedback" v-if="state === false">
        {{ invalidFeedback }}
      </div>
      <small
        id="helpId"
        class="form-text text-danger"
        v-if="errors.items.find(f => f.field === name)"
        >{{ errors.items.find(f => f.field === name).msg }}</small
      >
    </div>
  </b-col>
</template>

<script>
import moment from 'moment'
export default {
  props: {
    star: Boolean,
    label: {
      type: String,
      default: ''
    },
    pl: {
      type: String,
      default: ''
    },
    col: {
      type: Number,
      default: 12
    },

    id: String,
    value: null,
    state: null,
    invalidFeedback: String,
    format: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    startRef: null,
    endRef: null,
    now: {
      type: Boolean,
      default: false
    },
    /**
     * ----------------------------
     * @param minDate
     * @default false
     * ----------------------------
     * @true ไม่สามารถเลือกวันย้อนหลังได้
     * @false สามารถเลือกวันย้อนหลังได้
     */
    minDate: {
      type: Boolean,
      default: false
    },
    /**
     * ----------------------------
     * @param MaxDate
     * @default false
     * ----------------------------
     * @true ไม่สามารถเลือกวันล่วงหน้าได้
     * @false สามารถเลือกวันล่วงหน้าได้
     */
    maxDate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      name: 'name' + Math.floor(Math.random() * (0 - 99 + 999)) + 1,
      placeholder: this.pl ? this.pl : '',
      inputVal: null,
      daterangepicker: ''
    }
  },
  methods: {
    keydown(event) {
      event.preventDefault()
    }
  },
  mounted() {
    const self = this
    $(() => {
      self.daterangepicker = $(self.$el)
        .find('input')
        .daterangepicker(
          {
            format: self.format,
            locale: 'th',
            minDate: self.minDate ? moment() : self.minDate,
            maxDate: self.maxDate ? moment() : self.maxDate,
            autoApply: true,
            autoUpdateInput: true,
            showDropdowns: true
          },
          (start, end) => {
            self.$emit('getValue', {
              start: start.format(self.format).convertDate(),
              end: end.format(self.format).convertDate()
            })
          }
        )
      self.$emit('getValue', {
        start: moment()
          .format(self.format)
          .convertDate(),
        end: moment()
          .format(self.format)
          .convertDate()
      })
    })
  }
}
</script>
