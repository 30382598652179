<template>
  <b-input-group size="sm">
    <b-form-input
      v-model="inputVal"
      :id="id"
      :name="name"
      @keydown="keydown"
      :state="state"
      autocomplete="off"
      :disabled="disabled"
      :readonly="readonly"
      :placeholder="pl"
    />
    <b-input-group-append is-text>
      <i class="fas fa-calendar-alt" @click="show"></i>
    </b-input-group-append>
    <div class="invalid-feedback" v-if="state === false">
      {{ invalidFeedback }}
    </div>
  </b-input-group>
</template>

<script>
export default {
  props: {
    pl: String,
    id: String,
    name: String,
    value: null,
    state: null,
    invalidFeedback: String,
    format: {
      type: String,
      default: 'DD/MM/YYYY'
    },
    startRef: null,
    endRef: null,
    /**
     * ถ้าต้องการใช้วันปัจจุบัน ให้กรอก true เข้ามา
     */
    now: {
      type: Boolean,
      default: false
    },
    /**
     * ----------------------------
     * @param minDate
     * @default false
     * ----------------------------
     * @true ไม่สามารถเลือกวันย้อนหลังได้
     * @false สามารถเลือกวันย้อนหลังได้
     */
    minDate: {
      type: Boolean,
      default: false
    },
    /**
     * ----------------------------
     * @param MaxDate
     * @default false
     * ----------------------------
     * @true ไม่สามารถเลือกวันล่วงหน้าได้
     * @false สามารถเลือกวันล่วงหน้าได้
     */
    maxDate: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputVal: null,
      daterangepicker: ''
    }
  },
  watch: {
    inputVal(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.inputVal = val ? val.toBETable(true) : null
    },
    startRef: function(value) {
      if (value) {
        this.daterangepicker
          .data('daterangepicker')
          .minDate(moment(value.toCETable(true), 'DD/MM/YYYY'))

        if (
          moment(this.value.toCETable(true), 'DD/MM/YYYY') <
          moment(value.toCETable(true), 'DD/MM/YYYY')
        ) {
          this.value = value.toBETable(true)
        }
      }
    },
    endRef: function(value) {
      if (value) {
        this.daterangepicker
          .data('daterangepicker')
          .maxDate(moment(value.toCETable(true), 'DD/MM/YYYY'))

        if (
          moment(this.value.toCETable(true), 'DD/MM/YYYY') >
          moment(value.toCETable(true), 'DD/MM/YYYY')
        ) {
          this.value = value.toBETable(true)
        }
      }
    }
  },
  methods: {
    show() {
      const self = this
      $(() => {
        self.daterangepicker = $(self.$el)
          .find('input')
          .trigger('focus')
      })
    },
    keydown(event) {
      event.preventDefault()
    }
  },
  mounted() {
    const self = this

    if (this.value) {
      this.inputVal = this.value.toBETable(true)
    }
    // debugger

    self.daterangepicker = $(self.$el)
      .find('input')
      .daterangepicker({
        format: self.format,
        locale: 'th',
        minDate: self.minDate
          ? moment()
          : self.startRef
          ? moment(self.startRef.toCETable(true), 'DD/MM/YYYY')
          : false,
        maxDate: self.maxDate ? moment() : false,
        useCurrent: !!self.endRef
      })

    self.daterangepicker.on('dp.change', el => {
      self.inputVal = moment(el.date)
        .format(self.format)
        .toBETable(true)
      self.$emit('dp.change', el)
    })

    if (self.now && (self.inputVal == null || self.inputVal == '')) {
      if (
        self.startRef &&
        moment() < moment(self.startRef.toCETable(true), 'DD/MM/YYYY')
      ) {
        self.inputVal = self.startRef.toBETable(true)
      } else {
        self.inputVal = moment()
          .format('DD/MM/YYYY')
          .toBETable(true)
      }
    }
  }
}
</script>

<style>
.input-group-sm > .form-control:not(textarea) {
  height: auto;
}
</style>
