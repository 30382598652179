<template>
  <b-col :sm="col" class="xxxx">
    <div class="form-group">
      <label :for="name" v-if="label">{{ label }}</label>

      <v-select
        :label="trackLable"
        :options="optionSelect"
        v-model="selected"
        :disabled="disabled"
        :name="name"
        :id="name"
        :placeholder="pl"
        :clearable="clearable"
        @input="handelSelected"
      />

      <small
        id="helpId"
        class="form-text text-danger"
        v-if="errors.items.find((f) => f.field === name)"
        >{{
          errors.items
            .find((f) => f.field === name)
            .msg.replace("กรุณากรอก", "กรุณา ")
        }}</small
      >
    </div>
  </b-col>
</template>

<script>
/**
 * How To
 * ถ้าต้องการ ให้เลือกตัวเก่า ใน กรณีแก้ไข ให้ส่ง ID ของตัวเก่าแทนที่ v-model ตัวเก่า
 * @exampleUseOld
 * <form-select v-model='data' />
 * data = 'xxxxx'
 */
import vSelect from "vue-select"
import "vue-select/dist/vue-select.css"
export default {
  components: {
    vSelect,
  },
  props: {
    trackLable: {
      type: String,
      default: "name",
    },
    col: {
      type: Number,
      default: 12,
    },
    options: null,
    label: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: null,
    pl: {
      type: String,
      default: "",
    },
    url: {
      type: String,
      default: "",
    },
    clearable: {
      type: Boolean,
      default: true,
    },
    methods: {
      type: String,
      validator: function (value) {
        return ["post", "get"].indexOf(value) !== -1
      },
    },
    /**
     * เอาไว้บอกว่าจะเอาค่ามาเทียบกับ fill อะไร
     */
    findInFill: {
      type: String,
      default: null,
    },
    debugAble: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    options(val) {
      this.optionSelect = val
    },
    value: {
      handler: function (val) {
        if (!val) return ""
        const opt = this.options
        if (opt) {
          this.checkSetValue(val)
          return ""
        } else {
          setTimeout(() => {
            this.checkSetValue(val)
          }, 500)
        }
      },
      immediate: true,
    },

    url(val) {
      this.getResource()
    },
  },

  data() {
    return {
      name: "name" + Math.floor(Math.random() * (0 - 99 + 999)) + 1,
      placeholder: this.pl ? this.pl : "",
      selected: this.value,
      optionSelect: this.options,
    }
  },
  mounted() {
    this.getResource()
  },
  methods: {
    checkSetValue(val) {
      if (typeof val == "string" || typeof val == "number") {
        const tackLable = this.findInFill ?? this.trackLable

        const findData = this.optionSelect.find((opt) => opt[tackLable] == val)

        if (findData) {
          this.selected = findData
        }
        this.handelSelected()
      } else {
        this.selected = val
      }
      return ""
    },
    reload() {
      this.selected = ""
      this.getResource()
      this.handelSelected()
    },
    async getResource() {
      const url = this.url
      if (!url) return ""

      try {
        let data = ""
        if (this.methods === "post") {
          data = await this.api.post(url)
        } else {
          data = await this.api.get(url)
        }
        if (this.selected) {
          this.selected = await data.find((item) => item.id === this.selected)
        }
        this.optionSelect = data
      } catch (error) {
        console.error(error)
        return ""
      }
    },
    handelSelected() {
      const selected = this.convert.cp(this.selected)
      this.$emit("input", selected)
    },
  },
}
</script>
<style scrop>
.xxxx {
  padding: 2px 0;
  /* margin: 8px 0; */
  /* box-sizing: border-box; */
}
.vs__dropdown-toggle {
  width: 100%;
  /* padding: 1px 1px; */
  margin: 1px 1;
  box-sizing: border-box;
  padding: 0px 1px 1px 0px !important;
}
.vs__selected {
  margin-top: 0px !important;
}
.vs__actions {
  padding: 1px 12px 1px 1px !important;
}
</style>
