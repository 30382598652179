<template>
	<b-row class>
		<ThailandAutoComplete
			v-model="district"
			type="district"
			@select="select"
			label="ตำบล"
			placeholder="ตำบล..."
			class="col-sm-3"
		/>
		<ThailandAutoComplete
			v-model="amphoe"
			type="amphoe"
			@select="select"
			label="อำเภอ"
			placeholder="อำเภอ..."
			class="col-sm-3"
		/>
		<ThailandAutoComplete
			v-model="province"
			type="province"
			@select="select"
			label="จังหวัด"
			placeholder="จังหวัด..."
			class="col-sm-3"
		/>
		<ThailandAutoComplete
			v-model="zipcode"
			type="zipcode"
			@select="select"
			label="รหัสไปรษณีย์"
			placeholder="รหัสไปรษณีย์..."
			class="col-sm-3"
		/>
	</b-row>
</template>
<script>
/**
 * Document: https://biigpongsatorn.github.io/#/vue-thailand-address-autocompleter
 */
// import ThailandAutoComplete from 'vue-thailand-address-autocomplete'
import ThailandAutoComplete from './ThailandAutoComplete'
export default {
	props: {
		value: null,
	},
	components: {
		ThailandAutoComplete,
	},
	data() {
		return {
			district: '',
			amphoe: '',
			province: '',
			zipcode: '',
		}
	},
	watch: {
		value: {
			handler: function(value) {
				if (!value) return ''
				this.district = value.district
				this.amphoe = value.amphoe
				this.province = value.province
				this.zipcode = value.zipcode
			},
			immediate: true,
		},
	},
	methods: {
		select(address) {
			this.district = address.district
			this.amphoe = address.amphoe
			this.province = address.province
			this.zipcode = address.zipcode

			this.handelSelected()
		},
		handelSelected() {
			const _return = this.value
			_return.district = this.district
			_return.amphoe = this.amphoe
			_return.province = this.province
			_return.zipcode = this.zipcode
			_return.address_full = ''
			this.$emit('input', _return)
		},
	},
}
</script>
