//
export default [

  {
    path: `serviceRate`,
    name: `service-rate`,
    component: () => import('@app/ServiceRates'),
    // meta: {
    //   resource: 'ACL',
    //   action: 'client',
    // },
  },
]
